import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { UserModel } from '../models/user';
import { API } from '../data';
import { RouterService } from './router.service'; 

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public profile$: BehaviorSubject<UserModel> = new BehaviorSubject(new UserModel());

  constructor(
    public http: HttpService,
    public routerServcie: RouterService
  ) {
    this.loadProfile();
  }

  public getProfile() {
    return this.profile$.asObservable();
  }
  public loadProfile() {
    this.http.get<UserModel>(`${API.USER}/profile`).subscribe(profile => {
      this.profile$.next(profile)
    })
  }
  public loadProfileFromApi() {
    return this.http.get<UserModel>(`${API.USER}/profile`)
  }

  public find(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(API.USER);
  }
  public findByID(id: string): Observable<UserModel> {
    return this.http.get(`${API.USER}/${id}`)
  }

  public update(user: UserModel) {
    this.http.post(`${API.USER}/profile/update`, user).subscribe(res => {
      this.loadProfile();
      this.goToIndex();
    })
  }

  public async setProfilePhoto(photo: File, user_id: string) {
    let fd: FormData = new FormData();
    const data_url = await this.getDataUrlFromImage(photo);
    const cropped = await this.crop(data_url, 1);
    const file: Blob = this.dataURItoBlob(cropped); 
    fd.append('file', file, photo.name);
    return this.http.post(`/user/photo/${user_id}`, fd);
  }

  public goToIndex() {
    this.routerServcie.navigate(['/profile'])
  }


  public getDataUrlFromImage(image) {
    return new Promise(resolve => {
      const fr: FileReader = new FileReader();
      fr.onload = function (event) {
        resolve(event.target.result);
      };
      fr.readAsDataURL(image)
    })
  }
  public dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }
  public crop(url, aspectRatio) {

    // we return a Promise that gets resolved with our canvas element
    return new Promise(resolve => {

      // this image will hold our source image data
      const inputImage = new Image();
      inputImage.onload = () => {
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;

        const inputImageAspectRatio = inputWidth / inputHeight;

        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }
        const outputX = (outputWidth - inputWidth) * .5;
        const outputY = (outputHeight - inputHeight) * .5;

        const outputImage = document.createElement('canvas');

        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        const ctx = outputImage.getContext('2d');
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage.toDataURL());
      };
      inputImage.src = url;
    })

  }
}
