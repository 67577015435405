import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { API } from '../data';
import { map } from 'rxjs/operators';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    public http: HttpService,
    public routerService: RouterService
  ) { }

  public getContacts(filter: any): Observable<any> {
    return this.http.post(API.CONTACT, filter)
  }
  public getProjects() {
    return this.http.get<any[]>('/lexia/projects/list/all')
  }

  public createContact(contact) {
    this.http.post('/lexia/create', contact).subscribe((contact: any) => {
      this.routerService.navigate(['contacts', 'clients', contact.Number])
    })
  }

  public searchClientsByName(name: string) {
    return this.http.get(`/lexia/clients/search?name=${name}`);
  }
}
