import {
  Component,
  OnInit,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { JudgeService } from "src/app/services/judge.service";
import { JudgeModel } from "src/app/models/judge";
import { Subscription } from "rxjs";
import { CaseService } from "src/app/services/case.service";

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => JudgeInputComponent),
  multi: true,
};

@Component({
  selector: "app-judge-input",
  templateUrl: "./judge-input.component.html",
  styleUrls: ["./judge-input.component.less"],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR],
})
export class JudgeInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() input: any[];
  @Input() cloud: boolean = false;
  @Input() showModal = true;
  private _value: any;
  public show: boolean = false;
  public judges: JudgeModel[] = [];
  public selected_ids: string[] = [];
  public selected: any = [];
  public caseSettings$: Subscription;
  public settings: any = {};
  public judges_result: any[] = [];
  public search: string;
  @Input() main_judge: string;

  @Output() setMainJudge: EventEmitter<any> = new EventEmitter();
  @Output() setJudges: EventEmitter<any> = new EventEmitter();
  constructor(
    public judgeService: JudgeService,
    public caseService: CaseService
  ) {}

  ngOnInit(): void {
    this.judgeService.find().subscribe((judges) => {
      this.judges = judges;
      this.judges_result = judges;
      // if (this.input) {
      //   let judges = this.judges.filter(judge => this.input.indexOf(judge._id) != -1);
      //   judges.forEach(judge => this.selectJudge(judge));
      // }
      this.selected_ids = this.input;
    });

    this.caseSettings$ = this.caseService
      .getCaseSettings$()
      .subscribe((settings) => {
        this.settings = settings;
      });

    if (this.selected.length) {
      this.selected.forEach((j) => this.selected_ids.push(j._id));
    }
  }
  ngOnDestroy() {
    this.caseSettings$.unsubscribe();
  }

  public toggleUpdate() {
    this.caseService.toggleCaseSetting(
      this.settings._id,
      "judges",
      !this.settings.judges
    );
  }
  public toggleJudgesUpdate() {
    if (this.settings.court) {
      this.caseService.toggleCaseSetting(
        this.settings._id,
        "judges",
        !this.settings.judges
      );
    }
  }

  public selectJudge(judge: string) {
    this.setInputValue();
  }
  public searchCase(search_str) {
    if (search_str) {
      this.judges_result = this.judges.filter(
        (c) => c.name.toUpperCase().indexOf(search_str.toUpperCase()) != -1
      );
    } else {
      this.judges_result = this.judges;
    }
  }

  public selectedJudge(id) {
    return this.selected_ids.indexOf(id) != -1;
  }

  public unSelect(i) {
    this.selected_ids.splice(i, 1);
    this.selected.splice(i, 1);
    this.setValue(this.selected_ids);
  }

  public setInputValue() {
    this.setJudges.emit(this.selected_ids);
  }

  public setValue(value) {
    this._value = value;
    this.notifyValueChange();
  }

  public open() {
    this.show = true;
  }
  public close() {
    this.show = false;
  }

  public toggle() {
    if (!this.show && this.settings.judges && this.showModal) {
      this.toggleUpdate();
    }
    this.show = !this.show;
  }

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  public addJudge() {
    this.selected_ids.push("");
  }

  public judgeDelete(i) {
    this.selected_ids.splice(i, 1);
  }
  public isResponsible(id) {
    return this.main_judge == id;
  }
  public setResponsible(id) {
    this.main_judge = id;
    this.setMainJudge.emit(id);
  }
}
