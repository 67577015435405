import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'; 
import { HISTORY_FIELDS } from '../../../data'; 
import { CaseService } from 'src/app/services/case.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.less']
})
export class HistoryModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  public history: any;
  public fields = HISTORY_FIELDS;
  public full_history: any[] = [];
  constructor(
    public datePipe: DatePipe,
    public caseService: CaseService
  ) { }

  ngOnInit(): void { 
    this.caseService.loadCaseFullHistory(this.data.case_id).subscribe(data => {
      this.full_history = data as any;
    })
  }

  public closeModal() {
    this.close.emit();
  }

  public openHistory(el) {
    if (!el.open) {
      el.open = true;
      return false;
    }
    el.open = !el.open;
  }

  public getTaskDate(date) {
    let hours = new Date(date).getHours();
    if (hours > 0) {
      return this.datePipe.transform(date, 'dd.MM.yy, HH:mm')
    } else {
      return this.datePipe.transform(date, 'dd.MM.yy')
    }
  }
  public isTomorrow(date) {
    let now = new Date();
    let event_date = new Date(date);
    let tomorrow = `${now.getDate() + 1}.${now.getMonth()}`;
    return tomorrow == `${event_date.getDate()}.${event_date.getMonth()}` || `${now.getDate()}.${now.getMonth()}` == `${event_date.getDate()}.${event_date.getMonth()}`;
  }

}
