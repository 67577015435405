import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-register-data",
  templateUrl: "./register-data.component.html",
  styleUrls: ["./register-data.component.less"],
})
export class RegisterDataComponent implements OnInit {
  public registryFields = [
    "code",
    "fullName",
    "shortName",
    "capital",
    "phones",
    "email",
    "management",
    "location",
    "objectName",
    "primaryActivity",
    "additionallyActivities",
    "registrationNumber",
    "status",
  ];

  public words = {
    code: "ЕДРПОУ/ІПН",
    fullName: "Повна назва",
    shortName: "Назва",
    capital: "Капітал",
    phones: "Телефон",
    email: "E-mail",
    management: "Менеджмент",
    location: "Адреса",
    objectName: "Орган реєстрації",
    primaryActivity: "Основна діяльність",
    additionallyActivities: "Додаткова",
    registrationNumber: "Реєстраційний номер",
    status: "Статус",
  };

  @Input() public data!: any;
  @Output() onUpdate: EventEmitter<string> = new EventEmitter();
  public lexiaProject: any;
  constructor(private http: HttpService) {}

  ngOnInit(): void {}

  public getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  public getValueType(key: string, value: any) {
    switch (key) {
      case "economicActivity":
        return "economicActivity";
        break;

      default:
        break;
    }

    return typeof value;
  }

  public isDateField(key: string) {
    return key.includes("Date");
  }

  public update() {
    this.onUpdate.emit();
  }
}
