import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ModalService } from "src/app/services/modal.service";
import { CaseService } from "src/app/services/case.service";
import { MODAL } from "src/app/data";
import { Subscription } from "rxjs";
import { SettingService } from "src/app/services/setting.service";

@Component({
  selector: "app-case-procedural",
  templateUrl: "./case-procedural.component.html",
  styleUrls: ["./case-procedural.component.less"],
})
export class CaseProceduralComponent implements OnInit, OnDestroy {
  @Input() number: string;
  @Input() cloud: boolean = true;
  @Input() hide_create: boolean = false;
  @Input() claimId: string;
  @Input() caseId: string;
  public settings$: Subscription;
  public caseSettings$: Subscription;
  public settings: any = {};
  public history: any[] = [];
  constructor(
    public modalService: ModalService,
    public caseService: CaseService,
    public settingService: SettingService
  ) {}

  ngOnInit(): void {
    this.loadProceduralHistory(this.number);
    this.settings$ = this.settingService.getReload().subscribe((type) => {
      if (type == "procedural") {
        this.loadProceduralHistory(this.number);
      }
    });

    this.caseSettings$ = this.caseService
      .getCaseSettings$()
      .subscribe((settings) => {
        this.settings = settings;
      });
  }

  ngOnDestroy() {
    this.settings$.unsubscribe();
    this.caseSettings$.unsubscribe();
  }
  public loadProceduralHistory(number: string) {
    this.caseService
      .loadProcedural(number, this.claimId)
      .subscribe((history) => {
        this.history = history;
      });
  }

  public toggleUpdate() {
    this.caseService.toggleCaseSetting(
      this.settings._id,
      "procedural",
      this.settings.procedural ? !this.settings.procedural : true
    );
  }

  public updateProcedural() {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: this.number,
        type: "update_procedural",
      },
    });
  }

  public onItemTransfer(id) {
    this.modalService.openModal({
      type: "TRANSFER",
      data: {
        caseId: this.caseId,
        claimId: this.claimId,
        itemId: id,
        type: "history",
      },
    });
  }

  public delete(id) {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: id,
        type: "procedural",
      },
    });
  }

  public addProcedural() {
    this.modalService.openModal({
      type: MODAL.ADD_PROCEDURAL,
      data: {
        case_number: this.number,
        action: "create",
        claim: this.claimId,
      },
    });
  }

  public editProcedural(item) {
    this.modalService.openModal({
      type: MODAL.ADD_PROCEDURAL,
      data: {
        case_number: this.number,
        obj: item,
        action: "edit",
      },
    });
  }
}
