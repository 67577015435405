import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public routerService: RouterService,
    public auhtService: AuthService,
    public router: Router,

  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auhtService.isLogin()) {
      return true;
    } else { 
      this.routerService.navigate(['/login'], {
        queryParams: {
          redirect_url: state.url
        }
      });
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardChildService implements CanActivateChild {
  public state: RouterStateSnapshot;
  constructor(
    public routerService: RouterService,
    public auhtService: AuthService,
    public router: Router
  ) {
    this.state = this.router.routerState.snapshot;
  }
  canActivateChild() {
    if (this.auhtService.isLogin()) {
      return true;
    } else { 
      this.router.navigate(['/login']);
      return false;
    }
  }
}