import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../services/user.service';
import { UserModel } from '../models/user';
import { SettingService } from '../services/setting.service';
import { Subscription } from 'rxjs';
import { CaseService } from '../services/case.service';
import { FilterDataModel } from '../models/setting';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profile: UserModel;
  public cases: any[] = [];
  public filter$: Subscription;
  public settings$: Subscription;
  public settings: any;
  public filter: any;
  constructor(
    public userService: UserService,
    public settingService: SettingService,
    public caseService: CaseService,
    public title: Title
  ) {
    this.userService.loadProfile();
    this.userService.loadProfileFromApi().subscribe(profile => {
      this.profile = profile;
      this.filter$ = this.settingService.getFilter().subscribe(filter => {
        if (!filter.users.length) {
          filter.users = [this.profile._id]
        }
        this.filter = filter;
        this.loadCases(this.filter);
      });
    })
  }

  ngOnInit(): void {
    this.title.setTitle(`Profile | Aequo Cases`)
    this.settings$ = this.settingService.getSettings().subscribe(settings => {
      this.settings = settings;
      // fix
    });
  }

  ngOnDestroy() { 
    this.filter$.unsubscribe();
    this.settings$.unsubscribe();
    this.settingService.closeFilter();
    this.settingService.dropFilter();
  }

  public toggleFilter() {
    this.settingService.toggleFilter();
  }

  public loadCases(filter) {
    this.caseService.getCasesFeed(filter).subscribe(cases => {
      this.cases = cases;
    })
  }

  public dropFilter() {
    this.settingService.dropFilter();
  }

}
