import { Component, OnInit } from "@angular/core";
import { MODAL } from "../../data";
import { ModalService } from "src/app/services/modal.service";
@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.less"],
})
export class ModalComponent implements OnInit {
  public modals = MODAL;
  public modal;
  constructor(public modalService: ModalService) {}

  ngOnInit() {
    this.modalService.getModal().subscribe((modal) => {
      this.modal = modal;
    });
  }

  public closeModal() {
    this.modalService.closeModal();
  }
}
