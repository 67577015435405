import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    public router: Router
  ) { }

  public navigate(commands: any[], options?: NavigationExtras) {
    this.router.navigate(commands, options);
  }
}
