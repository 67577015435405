import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  HostListener,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { CaseService } from "src/app/services/case.service";
import { Subscription } from "rxjs";
import { SettingService } from "src/app/services/setting.service";

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.less"],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR],
})
export class SelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor, OnChanges
{
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() cloud: boolean = false;
  @Input() cloud_field: string = "";
  @Input() title: string = "Виберіть";
  @Input() titleField: string;
  @Input() valueField: string;
  @Input() data: any[] = [];
  @Input() mode: "multi" | "single" = "single";
  @Input() input: string | any[];
  @Input() error: string = "VALID";
  @Input() search: string = "";
  @Input() searchField: string;
  @Input() show_search: boolean = false;
  @Input() closeOnSelect: boolean = false;
  public list: any = [];
  public selected_ids: any = [];
  public single_value: any;
  public multi_value: any[] = [];
  public text_value: string = "";
  public show: boolean = false;
  public caseSettings$: Subscription;
  public settings: any = {};
  private _value: any;
  public dropFitler$: Subscription;
  constructor(
    private eRef: ElementRef,
    public caseService: CaseService,
    public cdr: ChangeDetectorRef,
    public settingsService: SettingService
  ) {}

  ngOnInit(): void {
    this.list = this.data;
    if (this.isSingle() && this.input) {
      const tempValue = this.data.filter(
        (el) => el[this.valueField] == this.input
      )[0];
      if (tempValue) this.selectItem(tempValue);
    } else if (!this.isSingle() && this.input) {
      console.log(this.input);
      this.selected_ids =
        typeof this.input == "object" ? this.input : [this.input];
      const tempValue = this.data.filter(
        (el) => this.input.indexOf(el[this.valueField]) != -1
      );
      if (tempValue) this.multi_value = tempValue;
    }
    this.caseSettings$ = this.caseService
      .getCaseSettings$()
      .subscribe((settings) => {
        this.settings = settings;
      });
    this.dropFitler$ = this.settingsService.getEmptyFilter$().subscribe(() => {
      this.selected_ids = [];
      this.multi_value = [];
    });
  }
  ngOnDestroy() {
    this.caseSettings$.unsubscribe();
    this.dropFitler$.unsubscribe();
  }

  public selectItem(item) {
    if (this.isSingle()) {
      this.error = "VALID";
      this.text_value = item[this.titleField];
      this.single_value = item[this.valueField];
      this.close();
      this.setValue(this.single_value);
      this.selected.emit(this.single_value);
    } else {
      if (!this.isSelected(item)) {
        this.selected_ids.push(item[this.valueField]);
        this.multi_value.push(item);
        this.setValue(this.selected_ids);
        this.selected.emit(this.selected_ids);
      } else {
        let index = this.multi_value.indexOf(item[this.valueField]);
        this.multi_value.splice(index, 1);
        this.selected_ids.splice(index, 1);
        this.setValue(this.selected_ids);
        this.selected.emit(this.selected_ids);
      }
    }

    if (this.closeOnSelect) {
      this.close();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.list = changes.data.currentValue;
    }

    if (this.isSingle() && this.input) {
      const tempValue = this.data.filter(
        (el) => el[this.valueField] == this.input
      )[0];
      if (tempValue) this.selectItem(tempValue);
    }
    this.cdr.detectChanges();
  }

  public isSelected(item) {
    let selected = false;
    if (this.isSingle()) {
      selected = this.single_value == item[this.valueField] ? true : false;
    } else {
      selected =
        this.selected_ids.indexOf(item[this.valueField]) != -1 ? true : false;
    }
    return selected;
  }

  public toggleUpdate() {
    this.caseService.toggleCaseSetting(
      this.settings._id,
      this.cloud_field,
      !this.settings[this.cloud_field]
    );
  }

  public unSelect(i) {
    this.selected_ids.splice(i, 1);
    this.multi_value.splice(i, 1);
    this.setValue(this.selected_ids);
    this.selected.emit(this.selected_ids);
  }

  public isSingle() {
    return this.mode == "single";
  }

  public open() {
    this.show = true;
  }
  public close() {
    this.show = false;
  }
  public toggle() {
    if (!this.show && this.settings[this.cloud_field]) {
      this.toggleUpdate();
    }
    this.show = !this.show;
  }

  public searchCase(search_str) {
    if (search_str) {
      this.list = this.data.filter(
        (c) =>
          c[this.searchField].toUpperCase().indexOf(search_str.toUpperCase()) !=
          -1
      );
    } else {
      this.list = this.data;
    }
  }

  public setValue(value) {
    this._value = value;
    this.notifyValueChange();
  }

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }
  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // this.toggle();
    } else {
      this.close();
    }
  }
}
