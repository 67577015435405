import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-id-token',
  templateUrl: './id-token.component.html',
  styleUrls: ['./id-token.component.less']
})
export class IdTokenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
