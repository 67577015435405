import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    public http: HttpService
  ) { }

  public getComments(target_id) {
    return this.http.get(`/comment/${target_id}`);
  }
  public addComment(data, target_id) {
    return this.http.post(`/comment/${target_id}`, data)
  }
  public deleteComment(id) {
    return this.http.delete('/comment', id)
  }
}
