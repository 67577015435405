import { Component, OnInit, Input } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { SettingService } from 'src/app/services/setting.service';
import { CourtService } from 'src/app/services/court.service';
import { CaseService } from 'src/app/services/case.service';
import { FilterDataModel } from 'src/app/models/setting';
import { CourtModel } from 'src/app/models/court';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-contacts',
  templateUrl: './filter-contacts.component.html',
  styleUrls: ['./filter-contacts.component.less']
})
export class FilterContactsComponent implements OnInit {
  @Input() page: string = '';
  public court_name$: Subject<string> = new Subject();
  public court_name: string;
  public court_result: CourtModel[] = [];
  public filterData: FilterDataModel = new FilterDataModel();
  public filter: any = {};
  constructor(
    public caseService: CaseService,
    public courtService: CourtService,
    public settingSservice: SettingService
  ) { }

  ngOnInit() {
    this.settingSservice.getEmptyFilter$().subscribe(fitler => {
      this.filterData = fitler;
    })
    this.caseService.getCasesFilter().subscribe(filter => {
      this.filter = filter;
    });
    this.court_name$.pipe(debounceTime(200)).subscribe(query => {
      this.courtService.findByName(query).subscribe(courts => {
        this.court_result = courts;
      })
    })
  }

  public changeFilterArray(key, value) {
    const index = this.filterData[key].indexOf(value);
    if (index == -1) {
      this.filterData[key].push(value)
    } else {
      this.filterData[key].splice(index, 1)
    }
    this.settingSservice.changeFilter(this.filterData);
  }

  public updateFilter() {
    this.settingSservice.changeFilter(this.filterData)
  }

  public changeFilterType(key, value) {
    this.filterData[key] = value;
    this.settingSservice.changeFilter(this.filterData)
  }

  public changeCourtName(event) {
    this.court_name$.next(event)
  }

  public addCourt(court, i) { 
    if (!this.filter.courts.filter(c => c._id == court._id).length) {
      this.filter.courts.push({ _id: court._id, name: court.name });
    }
    this.court_result.splice(i, 1);
    this.changeFilterArray('courts', court._id)
  }


  public changeDate(date, field) {
    this.changeFilterType(field, date.value)
  }
  public isChecked(key, value) {
    return this.filterData[key].indexOf(value) != -1
  }

}
