import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import {
  AuthGuardService,
  AuthGuardChildService,
} from "./services/auth-guard.service";
import { LoginComponent } from "./login/login.component";
import { SearchComponent } from "./search/search.component";
import { ProfileComponent } from "./profile/profile.component";
import { LogoutComponent } from "./logout/logout.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { UserComponent } from "./user/user.component";
import { ProfileEditComponent } from "./profile-edit/profile-edit.component";
import { IdTokenComponent } from "./id-token/id-token.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./case/case.module").then((m) => m.CaseModule),
        canActivate: [AuthGuardService],
      },
      {
        path: "proceedings",
        loadChildren: () =>
          import("./proceedings/proceedings.module").then(
            (m) => m.ProceedingsModule
          ),
        canActivate: [AuthGuardService],
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./calendar/calendar.module").then((m) => m.CalendarModule),
        canActivate: [AuthGuardService],
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./contact/contact.module").then((m) => m.ContactModule),
        canActivate: [AuthGuardService],
      },
      {
        path: "monitoring",
        loadChildren: () =>
          import("./monitoring/monitoring.module").then(
            (m) => m.MonitoringModule
          ),
        canActivate: [AuthGuardService],
      },
      {
        path: "search",
        component: SearchComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "profile/edit",
        component: ProfileEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "access-denied",
        component: AccessDeniedComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "user/:id",
        component: UserComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  { path: "logout", component: LogoutComponent },
  { path: "login", component: LoginComponent },
  { path: "id_token", component: IdTokenComponent },
  // { path: 'access_token', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
