import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchService } from '../services/search.service';
import { CaseModel } from '../models/case';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('search', { static: true }) search_input: ElementRef;
  public subscription$: Subscription
  public query$: Subject<string> = new Subject();
  public query: string = '';
  public result: any[] = [];
  public not_found = false;
  constructor(
    public searchService: SearchService,
    public title: Title
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Search | Aequo Cases')
    this.subscription$ = this.query$.pipe(debounceTime(500)).subscribe(query => {
      if (!query) {
        return false;
      }
      this.searchService.search(query).subscribe(result => {
        this.result = result;
        this.not_found = this.result.length ? false : true;
      })
    });
    this.search_input.nativeElement.focus();
  }
  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
  ngAfterViewInit() {
    // this.search_input.nativeElement.focus();
  }


  public changeQuery(event) {
    this.query$.next(event)
  }

}
