registerLocaleData(uk);
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "./services/token.interceptor";
import { LoginComponent } from "./login/login.component";
import { LayoutComponent } from "./layout/layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import uk from "@angular/common/locales/uk";
import { registerLocaleData } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { SearchComponent } from "./search/search.component";
import { ProfileComponent } from "./profile/profile.component";
import { LogoutComponent } from "./logout/logout.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { UserComponent } from "./user/user.component";
import { ProfileEditComponent } from "./profile-edit/profile-edit.component";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
// import { MatPaginatorTranslation } from './shared/paginator.intl';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { IdTokenComponent } from "./id-token/id-token.component";

registerLocaleData(uk);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    SearchComponent,
    ProfileComponent,
    LogoutComponent,
    AccessDeniedComponent,
    UserComponent,
    ProfileEditComponent,
    IdTokenComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MatPaginatorModule,
    SharedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "uk-UA" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // { provide: MatPaginatorIntl, useClass: MatPaginatorTranslation }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
