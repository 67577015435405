import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CaseService } from "src/app/services/case.service";

@Component({
  selector: "app-case-side-output",
  templateUrl: "./case-side-output.component.html",
  styleUrls: ["./case-side-output.component.less"],
})
export class CaseSideOutputComponent implements OnInit, OnChanges {
  @Input() type: string = "claimant";
  @Input() side: any;
  @Input() valid = true;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Input() claim = false;

  public showDrawer = false;
  public types = {
    claimant: "Позивач",
    defendant: "Відповідач",
    third_side: "Третя сторона",
  };

  public sideForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    beneficiary: new FormControl(""),
    agent: new FormControl(""),
    code: new FormControl("", [
      Validators.minLength(8),
      Validators.maxLength(10),
      Validators.pattern(/^\s*-?[0-9]{8,10}\s*$/),
    ]),
  });
  public sides: any[] = [];
  constructor(public caseService: CaseService) {}

  ngOnInit(): void {
    console.log(this.claim);
  }

  ngOnChanges(changes: SimpleChanges) {}

  public onOpen() {
    this.sideForm.patchValue(this.side);
    this.showDrawer = true;
  }

  public onSideSave() {
    this.onSave.emit(this.sideForm.value);
    this.showDrawer = false;
  }

  public searchSides(event) {
    this.caseService
      .searchCaseSide(this.sideForm.value.name)
      .subscribe((sides) => (this.sides = sides as any));
  }

  public selectItem(value) {
    this.sideForm.controls.name.setValue(value.name);
    this.sides = [];
  }
}
