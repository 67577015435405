export class UserModel {
    _id?: string;
    name: string;
    email: string;
    email_user: string;
    phone: string;
    phone_internal: string;
    ad_id: string;
    photo: string;
    position: string;
    practice: string;
    license_number: string;
    leader: string;
    sharepoint_link: string;
    cretedAt?: string;
    updatedAt?: string;
}