import { Component, OnInit, Input, ViewChild, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimeInputComponent),
  multi: true
};

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.less'],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR]
})
export class DateTimeInputComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() date: Date;
  @Input() mode: 'single' | 'start_end' = 'start_end';
  @Input() dateObj: any;
  @Output() completed: EventEmitter<any> = new EventEmitter()
  public time_start: string = '';
  public time_end: string = '';
  private _value: any;
  public output = {
    start: null,
    end: null
  }
  constructor() { }

  ngOnInit(): void {
    if (this.dateObj.start) {
      this.output.start = this.dateObj.start;
      let temp = new Date(this.dateObj.start);
      this.date = temp;
      this.time_start = `${temp.getHours().toString().length == 1 ? '0' + temp.getHours().toString() : temp.getHours()}:${temp.getMinutes().toString().length == 1 ? '0' + temp.getMinutes().toString() : temp.getMinutes()}`;
    }
    if (this.dateObj.end) {
      this.output.end = this.dateObj.end;
      let temp = new Date(this.dateObj.end);
      this.time_end = `${temp.getHours().toString().length == 1 ? '0' + temp.getHours().toString() : temp.getHours()}:${temp.getMinutes().toString().length == 1 ? '0' + temp.getMinutes().toString() : temp.getMinutes()}`;
    }

    if (this.date) {
      this.setValue(new Date())
    }
  }
  public changeDate(date: Date) {
    this.output.start = new Date(this.date);
    this.emit();
  }
  public changeTime(time: string, type: string = 'start') {
    try {
      let temp_date = new Date(this.date);
      let hh_mm = time.split(':')
      temp_date.setHours(+hh_mm[0]);
      temp_date.setMinutes(+hh_mm[1]);
      this.output[type] = temp_date;
      if (type == 'start') {
        let end = new Date(temp_date);
        end.setHours(temp_date.getHours() + 1)
        this.output.end = end;
        this.time_end = `${end.getHours() < 10 ? '0' + end.getHours() : end.getHours()}:${end.getMinutes() < 10 ? '0' + end.getMinutes() : end.getMinutes()}`
        
      }
      this.emit();
    } catch (error) {
      console.log(error)
    }
  }

  public formatTime(key) {
    let result = [];
    let arr = this[key].split(':');
    if (+arr[0] > 23) {
      arr[0] = 23;
    }

    if (('' + arr[0]).length == 1) {
      arr[0] = '0' + arr[0]
    }
    if (+arr[1] > 59 || !arr[1]) {
      arr[1] = '00';
    }
    this[key] = arr.join(':'); 
    if (key == 'time_start') {
      this.changeTime(arr.join(':'));
    }

  }

  public emit() {
    if (this.mode == 'start_end' && this.output.start && this.output.end) {
      this.completed.emit(this.output)
    }
    if (this.mode == 'single' && this.output.start) {
      this.completed.emit(this.output)
    }
  }

  public setValue(value) {
    this._value = value;
    this.notifyValueChange();
  }

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }


}
