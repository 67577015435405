import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { CaseModel } from "../models/case";
import { API, MODAL } from "../data";
import {
  Observable,
  Subject,
  Subscriber,
  BehaviorSubject,
  ReplaySubject,
} from "rxjs";
import { RouterService } from "./router.service";
import { CourtModel } from "../models/court";
import { map } from "rxjs/operators";
import { ModalService } from "./modal.service";

@Injectable({
  providedIn: "root",
})
export class CaseService {
  public case_id$: Subject<string> = new Subject();
  public caseSetting$: BehaviorSubject<any> = new BehaviorSubject({});
  public favorites$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public updateCases$: ReplaySubject<any> = new ReplaySubject(null);
  private favorites: string[] = [];
  public filter: any;
  constructor(
    public http: HttpService,
    public modalService: ModalService,
    public routerService: RouterService
  ) {
    this.getFavorites();
  }

  public getCaseId() {
    return this.case_id$.asObservable();
  }

  public onCasesUpdate() {
    return this.updateCases$.asObservable();
  }

  public setCaseId(id) {
    this.case_id$.next(id);
  }

  public getCaseSettings$() {
    return this.caseSetting$.asObservable();
  }

  public getCases(): Observable<CaseModel[]> {
    return this.http.get(API.CASE);
  }

  public create(caseObj: CaseModel) {
    this.http.post<CaseModel>(API.CASE, caseObj).subscribe((data) => {
      this.goToCase(data._id, data.case_type);
    });
  }

  public addToArchive(id, data) {
    return this.http.post(`${API.CASE}/archive/${id}`, data);
  }
  public removeFromArchive(id) {
    return this.http.get(`${API.CASE}/unarchive/${id}`);
  }

  public loadCaseHistory(
    id
  ): Observable<{ history: any[]; judgesHistory: any[] }> {
    return this.http.get(`${API.CASE}/history/${id}`);
  }
  public loadCaseFullHistory(
    id
  ): Observable<{ history: any[]; judgesHistory: any[] }> {
    return this.http.get(`${API.CASE}/history-full/${id}`);
  }
  public updateProcedural(item) {
    return this.http.update(`${API.CASE}/procedural`, "", item);
  }
  public deleteProcedural(id) {
    return this.http.delete(`${API.CASE}/procedural`, id);
  }
  public getCaseData(id): Observable<any> {
    this.loadCaseSettings(id);
    return this.http.get(`${API.CASE}/data/${id}`);
  }
  public getCasesFeed(filter?): Observable<CaseModel[]> {
    this.filter = filter;
    return this.http.post(`${API.CASE}/feed`, { filter }).pipe(
      map((cases: CaseModel[]) => {
        cases.sort((a, b) => {
          let a_l: any = a.events.length ? true : false;
          let b_l: any = b.events.length ? true : false;
          return b_l - a_l;
        });
        return cases;
      })
    );
  }
  public getCasesFilter(): Observable<any> {
    return this.http.get(`${API.CASE}/filter`);
  }
  public findEditCase(id: string): Observable<CaseModel> {
    this.loadCaseSettings(id);
    return this.http.get(`${API.CASE}/edit/${id}`);
  }
  public update(id: string, data: CaseModel) {
    this.http.update(`${API.CASE}/edit`, id, data).subscribe((updated) => {
      this.goToCase(id, data.case_type);
    });
  }

  public toggleCaseSetting(id, field, value) {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: id,
        field: field,
        value: value,
        type: "toggle_field",
      },
    });
  }
  public saveCaseSetting(id, field, value) {
    this.http
      .get(`${API.CASE}/setting/edit?id=${id}&field=${field}&value=${value}`)
      .subscribe((res: any) => {
        this.loadCaseSettings(res.case_id);
      });
  }

  public loadCaseSettings(case_id) {
    this.http.get(`${API.CASE}/setting/${case_id}`).subscribe((settings) => {
      this.caseSetting$.next(settings);
    });
  }

  public goToCase(id, type?) {
    let path = type == "case" ? "case" : "proceedings";
    this.routerService.navigate(["/", path, id]);
  }
  public goToIndex() {
    this.routerService.navigate(["/cases"]);
  }

  public loadFromAPI(number, judgment_code) {
    return this.http.get(
      `${API.CASE}/source?number=${number}&judgment_code=${judgment_code}`
    );
  }
  public loadProcedural(number, claim = null): Observable<any[]> {
    return this.http.get(
      `${API.CASE}/procedural?number=${number}&claim=${claim}`
    );
  }

  public addProcedural(item): Observable<any> {
    return this.http.post(`${API.CASE}/add-procedural`, item);
  }

  // public getLexiaProject(number): Observable<any[]> {
  //   return this.http.get(`/lexia/projects/${number}`);
  // }

  public getLexiaProject(number) {
    return this.http.get(`/lexia/projects/${number}`);
  }
  public getLexiaClient(number) {
    return this.http.get(`/lexia/projects/client/${number}`);
  }

  public exportWord(id) {
    this.http.get(`/case/export/word/${id}`).subscribe((res: any) => {
      if (res.link) {
        window.open(res.link, "_blank");
      }
    });
  }
  public exportExcel(filter) {
    this.http.post(`/case/export/excel`, filter).subscribe((res: any) => {
      if (res.link) {
        window.open(res.link, "_blank");
      }
    });
  }

  public updateCaseFromAPI(number) {
    return this.http.get(`/case/sync?number=${number}`);
  }
  public updateProceduralFromAPI(number) {
    return this.http.get(`/case/procedural/sync?number=${number}`);
  }
  public updateEventsFromAPI(number) {
    return this.http.get(`/case/events/sync?number=${number}`);
  }

  public addNUmberToCase(old, new_number) {
    this.http
      .get(`/case/case-number/add?old_number=${old}&new_number=${new_number}`)
      .subscribe(() => {});
  }

  public addToFavorites(case_id) {
    this.http.get(`/case/favorite/add/${case_id}`).subscribe((res) => {
      this.getFavorites();
    });
  }

  public deleteFavorites(case_id) {
    this.http.get(`/case/favorite/remove/${case_id}`).subscribe((res) => {
      this.getFavorites();
    });
  }

  public getFavorites() {
    this.http.get<string[]>(`/case/favorite/get`).subscribe((list) => {
      this.favorites = list;
      this.favorites$.next(list);
    });
  }

  public uploadFiles(case_id, files: File[]) {
    let fd = new FormData();
    for (let i = 0; i < files.length; i++) {
      fd.append(files[i].name, files[i], files[i].name);
    }
    return this.http.post<any[]>(`/case/files/${case_id}`, fd);
  }

  public getFiles(case_id) {
    return this.http.get<any[]>(`/case/files/${case_id}`).pipe(
      map((files) => {
        files.forEach((file) => {
          file.ext = file.filename.split(".").reverse()[0];
          file.path = file.path.replace(/\\/g, "/");
        });
        return files;
      })
    );
  }

  public deleteFile(case_id) {
    return this.http.delete<any>(`/case/files`, case_id);
  }

  public searchCaseSide(name) {
    return this.http.get(`${API.CASE}/sides/search?name=${name}`);
  }

  public getFavorites$() {
    return this.favorites$.asObservable();
  }

  public caseInFavorite(case_id) {
    return this.favorites.indexOf(case_id) != -1;
  }

  public setCaseSideCode(
    caseId: string,
    sideId: string,
    code: number,
    type: string,
    claim = false
  ) {
    return this.http.post(`${claim ? "/claim" : "/case"}/sides/set/${caseId}`, {
      sideId,
      code,
      type,
    });
  }

  public getCaseSideData(code: number, update = 0) {
    return this.http.get(`${API.CASE}/sides/get/${code}?update=${update}`);
  }
}
