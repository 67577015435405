import { Component, OnInit, forwardRef, Input, OnDestroy } from "@angular/core";
import { CourtService } from "src/app/services/court.service";
import { CourtModel } from "src/app/models/court";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { CaseService } from "src/app/services/case.service";

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CourtInputComponent),
  multi: true,
};

@Component({
  selector: "app-court-input",
  templateUrl: "./court-input.component.html",
  styleUrls: ["./court-input.component.less"],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR],
})
export class CourtInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() court: any;
  @Input() cloud: boolean = false;
  @Input() showModal = true;
  public courts: CourtModel[];
  private _value: any;
  public court_name$: Subject<string> = new Subject();
  public court_name: string;
  public court_result: CourtModel[] = [];
  public caseSettings$: Subscription;
  public settings: any = {};

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }

  constructor(
    public courtService: CourtService,
    public caseService: CaseService
  ) {}

  ngOnInit() {
    if (this.court) {
      this.selectCourt(this.court);
    }
    if (typeof this.court == "string") {
      this.courtService.findByID(this.court).subscribe((court) => {
        this.selectCourt(court);
      });
    }
    this.court_name$.pipe(debounceTime(200)).subscribe((query) => {
      this.courtService.findByName(query).subscribe((courts) => {
        this.court_result = courts;
      });
    });
    this.caseSettings$ = this.caseService
      .getCaseSettings$()
      .subscribe((settings) => {
        this.settings = settings;
      });
  }
  ngOnDestroy() {
    this.caseSettings$.unsubscribe();
  }

  public toggleUpdate() {
    this.caseService.toggleCaseSetting(
      this.settings._id,
      "court",
      !this.settings.court
    );
  }
  public toggleCourtUpdate() {
    console.log(this.settings.court, this.showModal);
    if (this.settings.court && this.showModal) {
      this.caseService.toggleCaseSetting(
        this.settings._id,
        "court",
        !this.settings.court
      );
    }
  }
  public changeCourtName(event) {
    this.court_name$.next(event);
  }

  public selectCourt(court) {
    this._value = court._id;
    this.notifyValueChange();
    this.court_name = court.name;
    this.court_result = [];
  }

  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
