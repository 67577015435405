import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { API } from '../data';
import { Observable } from 'rxjs';
import { JudgeModel } from '../models/judge';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class JudgeService {

  constructor(
    public http: HttpService,
    public routerService: RouterService
  ) { }

  public find(type: string = 'judge'): Observable<JudgeModel[]> {
    return this.http.get(`${API.JUDGE}?type=${type}`)
  }
  public getJudgeDeclarations(name) { 
    return this.http.get(`${API.JUDGE}/declarations?name=${name}`)
  }
  public getJudgeContact(id): Observable<JudgeModel> {
    return this.http.get(`${API.JUDGE}/contact/${id}`)
  }
  public findByID(id): Observable<JudgeModel> {
    return this.http.get(`${API.JUDGE}/${id}`);
  }
  public create(judge: JudgeModel) {
    return this.http.post(API.JUDGE, judge)
  }
  public update(id, judge: JudgeModel) {
    return this.http.update(API.JUDGE, id, judge)
  }

  public delete(id) {
    return this.http.delete(API.JUDGE, id);
  }

  public goToIndex() {
    this.routerService.navigate(['/contacts'])
  }
}
