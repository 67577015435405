import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserModel } from '../models/user';
import { UserService } from '../services/user.service';
import { FormService } from '../services/form.service';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OptionService } from '../services/option.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.less']
})
export class ProfileEditComponent implements OnInit, OnDestroy {
  public profile$: Subscription;
  public profile: UserModel;
  public profileForm: FormGroup;
  public practices: any;
  public init = false;
  public users: any = [];
  constructor(
    public userService: UserService,
    public formService: FormService,
    public optionService: OptionService
  ) {
    this.profileForm = this.formService.getUserGroup();
    this.profile$ = this.userService.getProfile().subscribe(profile => {
      this.profile = profile;
      if (profile._id)
        this.profileForm = this.formService.getUserGroupWithData(profile);
    });
    this.optionService.findByType('practice').subscribe(list => {
      this.practices = list;
    });
    this.userService.find().subscribe(users => {
      this.users = users;
    })

  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.profile$.unsubscribe()
  }

  public selectSimple(value, key) {
    this.profileForm.controls[key].setValue(value);
  }

  public handleFileInput(files) {
    const result = this.userService.setProfilePhoto(files[0], this.profile._id)
    result.then(res => {
      res.subscribe((data: any) => { 
        this.profileForm.controls.photo.setValue(data.photo);
      })
    })
    // .subscribe((res: any) => {
    //   this.profileForm.controls.photo.setValue(res.photo); 
    // })
  }

  public updateProfile() {
    this.userService.update(this.profileForm.value)
  }

}
