import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { CaseService } from '../services/case.service';
import { SettingService } from '../services/setting.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserComponent implements OnInit, OnDestroy {
  public user_id: string;
  public user: any;
  public cases: any[] = [];
  public filter$: Subscription;
  public settings$: Subscription;
  public settings: any;
  public filter: any;
  constructor(
    public settingService: SettingService,
    public caseService: CaseService,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public title: Title
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.user_id = params.id;
      this.loadUSer(this.user_id)
      this.filter$ = this.settingService.getFilter().subscribe(filter => {
        this.filter = filter;
        filter.users.push(this.user_id)
        this.loadCAses(filter);
      });
    })
  }

  ngOnInit(): void {
    this.settings$ = this.settingService.getSettings().subscribe(settings => {
      this.settings = settings;
    })
  }

  public loadUSer(id) {
    this.userService.findByID(id).subscribe(user => {
      this.title.setTitle(`${user.name} | Aequo Cases`)
      this.user = user;
    })
  }
  ngOnDestroy() {
    this.filter$.unsubscribe();
    this.settings$.unsubscribe();
    this.settingService.closeFilter();
    this.settingService.dropFilter()
  }

  public toggleFilter() {
    this.settingService.toggleFilter();
  }

  public dropFilter() {
    this.settingService.dropFilter();
  }

  public loadCAses(filter) {
    this.caseService.getCasesFeed(filter).subscribe(cases => {
      this.cases = cases;
    })
  }

}
