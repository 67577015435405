import { Directive, HostListener, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { MODAL } from 'src/app/data';

@Directive({
  selector: '[appJudgeModal]'
})
export class JudgeModalDirective {


  @Input('appJudgeModal') judge_id: string;
  
  @HostListener('click') onClick() { 
    this.modalService.closeModal();
    setTimeout(() => {
      this.modalService.openModal({
        type: MODAL.HELPER,
        data: {
          _id: this.judge_id,
          action: 'view'
        }
      })
    }, 100)
  }
  constructor(
    public modalService: ModalService
  ) { }

}
