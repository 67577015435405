import { Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UserModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { TaskService } from 'src/app/services/task.service';
import { CaseService } from 'src/app/services/case.service';

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserInputComponent),
  multi: true
};
@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.less'],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR]
})
export class UserInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() title: string = 'Відповідальний';
  @Input() case: any;
  @Input() selected: string;
  public users: UserModel[];
  private _value: any;
  public users_list = [];

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }

  constructor(
    public userService: UserService,
    public caseService: CaseService
  ) {
    this.userService.find().subscribe(users => {
      this.users = users;
    });
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.case.currentValue) {
        this.caseService.getCases().subscribe(cases => {
          let caseObj = cases.find(c => c.number == this.case);
          let users_obj = {}
          if (caseObj) {
            this.users_list = [];
            caseObj.team.forEach(user => {
              users_obj[user] = this.users.find(u => u._id == user);
              this.users_list.push(users_obj[user])
            });
            this.users.forEach(user => {
              if (!users_obj[user._id]) {
                users_obj[user._id] = user;
                this.users_list.push(user);
              }
            });
          } 
        })
      }
    } catch (error) {

    }
  }
  public setValue(id) {
    this.selected = id;
    this._value = id;
    this.notifyValueChange();
  }



  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }


}
