import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { AuthService } from './auth.service'
import { catchError } from 'rxjs/operators'
import { RouterService } from './router.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public authService: AuthService,
        public router: RouterService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = req.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.getToken()}`
            }
        })
        return next.handle(request).pipe(catchError((error: any) => {
            const err = error.error;
            if (err.status == 403) {
                this.router.navigate(['/access-denied'])
            }
            if (err.status == 401) {
                this.router.navigate(['logout'])
            }
            return of(error)
        }))
    }
}