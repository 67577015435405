import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { CaseService } from "src/app/services/case.service";
import { EventService } from "src/app/services/event.service";
import { ModalService } from "src/app/services/modal.service";
import { MODAL } from "src/app/data";
import { Subscription } from "rxjs";
import { SettingService } from "src/app/services/setting.service";

@Component({
  selector: "app-case-events",
  templateUrl: "./case-events.component.html",
  styleUrls: ["./case-events.component.less"],
})
export class CaseEventsComponent implements OnInit, OnDestroy {
  @Input() events: any[] = [];
  @Input() hide_create: boolean = false;
  @Input() hide_update: boolean = false;
  @Input() case_number: string;
  @Output() nextEvent: EventEmitter<any> = new EventEmitter();
  @Input() claimId: string;
  @Input() caseId: string;
  public settings$: Subscription;
  public today = new Date().getTime();
  public view = 1;
  public past_event: any;
  constructor(
    public caseService: CaseService,
    public eventService: EventService,
    public modalService: ModalService,
    public settingService: SettingService
  ) {}

  ngOnInit(): void {
    if (this.case_number) {
      this.getEvents(this.case_number);
    }
    this.settings$ = this.settingService.getReload().subscribe((type) => {
      if (type == "event") {
        this.getEvents(this.case_number);
      }
    });
  }
  ngOnDestroy() {
    this.settings$.unsubscribe();
  }
  public deleteEvent(id) {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: id,
        type: "event",
      },
    });
  }
  public updateeventsFromAPI() {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: this.case_number,
        type: "update_event",
      },
    });
  }

  public getEvents(number) {
    this.eventService
      .getCaseEvents(number, this.claimId)
      .subscribe((events) => {
        this.events = events;
        let filtered_events = [];
        events.forEach((event) => {
          if (new Date(event.date_start).getTime() > this.today) {
            filtered_events.push(event);
          }
        });
        this.nextEvent.emit(filtered_events);
      });
  }

  public openEvent(id) {
    this.modalService.openModal({
      type: MODAL.CALENDAR,
      data: {
        id: id,
        type: "event",
      },
    });
  }

  public addEvent(type: string = "event") {
    let obj = { court: "" };
    this.modalService.openModal({
      type: MODAL.ADD_EVENT,
      data: {
        type: type,
        case_number: this.case_number,
        action: "create",
        obj: obj,
        claim: this.claimId,
      },
    });
  }
  public openEdit(type, obj) {
    console.log({ obj });
    this.modalService.openModal({
      type: MODAL.ADD_EVENT,
      data: {
        type: type,
        case_number: this.case_number,
        action: "edit",
        obj: obj,
      },
    });
  }
  public isTomorrow(date) {
    let now = new Date();
    let event_date = new Date(date);
    let tomorrow = `${now.getDate() + 1}.${now.getMonth()}`;
    return (
      tomorrow == `${event_date.getDate()}.${event_date.getMonth()}` ||
      `${now.getDate()}.${now.getMonth()}` ==
        `${event_date.getDate()}.${event_date.getMonth()}`
    );
  }

  public filteredEvents() {
    let events = [];
    this.events.forEach((event, i) => {
      let event_date = new Date(event.date_start).getTime();
      if (this.view == 1 && event_date > this.today) {
        events.push(event);
      }
      if (this.view == 2 && event_date < this.today) {
        events.push(event);
      }
      if (event_date < this.today) {
        this.past_event = event;
      }
    });
    return this.view == 2 ? events.reverse() : events;
  }
  public showEvent(date) {
    let event_date = new Date(date).getTime();
    return (
      (this.view == 1 && event_date > this.today) ||
      (this.view == 2 && event_date < this.today)
    );
  }
  public onItemTransfer(id) {
    this.modalService.openModal({
      type: "TRANSFER",
      data: {
        caseId: this.caseId,
        claimId: this.claimId,
        itemId: id,
        type: "event",
      },
    });
  }
}
