export enum API {
    COURT = '/court',
    AUTH = '/auth',
    USER = '/user',
    OPTION = '/option',
    JUDGE = '/judge',
    CASE = '/case',
    TASK = '/task',
    EVENT = '/event',
    CONTACT = '/contact'
}

export enum MODAL {
    ADD_PROCEDURAL = 'ADD_PROCEDURAL',
    ADD_EVENT = 'ADD_EVENT',
    CASE_HISTORY = 'CASE_HISTORY',
    CALENDAR = 'CALENDAR',
    REMOVE = 'REMOVE',
    HELPER = 'HELPER',
    COURT = 'COURT',
    ARCHIVE = 'ARCHIVE',
    CLIENT = 'CLIENT'
}

export const HISTORY_FIELDS = {
    'title': 'Назву справи',
    'status': 'Статус',
    'court': 'Суд',
    'practice': 'Практику',
    'industry': 'Індустрію',
    'writing': 'Тип справи',
    'open_date': 'Дату відкриття',
    'private': 'Конфіденційність',
    'current_status': 'Поточний статус',
    'subject': 'Предмет справи',
    'circumstances': 'Обставини позову',
    'reason': 'Причини позову',
    'goal': 'Ціль',
    'responsible': 'Відповідального',
    'type': 'Тип справи',
    'instance': 'Інстанцію',
    'judgment_code': 'Юрисдикцію',
    'team': 'Команду',
    'procedural': 'Процесуальну історію',
    'event': 'Засідання',
    'task': 'Задачi',
    'claimant': 'Позивача',
    'defendant': 'Відповідача',
    'third_side': 'Третю сторону',
    'judges': 'Склад суддів'
}
export const CASE_TYPES = [
    {
        parent: {
            id: '1',
            text: 'Корпоративні спори'
        },
        children: [
            { id: '1.1', text: 'Корпоративні спори' },
            { id: '1.2', text: 'Спори  із менеджментом' },
            { id: '1.3', text: 'Інше' },
        ]
    },
    {
        parent: {
            id: '2',
            text: 'Спори щодо цінних паперів'
        },
        children: [
            { id: '2.1', text: 'Спори щодо цінних паперів (крім інших категорій)' },
            { id: '2.2', text: 'Інше' },
        ]
    },
    {
        parent: {
            id: '3',
            text: 'Спори щодо права власності'
        },
        children: [
            { id: '3.1', text: 'Спори щодо права власності (крім нерухомості)' },
            { id: '3.2', text: 'Спори щодо будівництва та нерухомого майна (крім земельних)' },
            { id: '3.3', text: 'Земельні спори' },
            { id: '3.4', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '4',
            text: 'Договірні спори; Міжнародні комерційні спори, та  спори з договорів перевезення '
        },
        children: [
            { id: '4.1', text: 'Спори щодо укладення, зміни, виконання, припинення,  розірвання, тлумачення і визнання недійсними договорів (крім інших категорій)' },
            { id: '4.2', text: 'Спори з  зовнішньоекономічних договорів купівлі-продажу, поставки, перевезення та інших договорів у сфері міжнародного комерційного обороту' },
            { id: '4.3', text: 'Спори щодо концесії та ДПП' },
            { id: '4.4', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '5',
            text: 'Спори з питань приватизації'
        },
        children: [
            { id: '5.1', text: 'Спори щодо оскарження приватизації' },
            { id: '5.2', text: 'Спори щодо виконання приватизаційних договорів' },
            { id: '5.3', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '6',
            text: 'Спори у банківській та фінансовій сфері. Забезпечення зобов’язань'
        },
        children: [
            { id: '6.1', text: 'Спори у банківській та фінансовій сфері (позики, кредити, лізинг тощо)' },
            { id: '6.2', text: 'Спори щодо застави, іпотеки' },
            { id: '6.3', text: 'Спори щодо інших видів забезпечення зобов’язань' },
            { id: '6.4', text: 'Інші банківські спори' },
            { id: '6.5', text: 'Спори з договорів страхування' },
            { id: '6.6', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '7',
            text: 'Банкрутство'
        },
        children: [
            { id: '7.1', text: 'Банкрутство' },
            { id: '7.2', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '8',
            text: 'Інтелектуальна власність. Недобросовісна конкуренція в сфері ІВ. Захищена інформація. Доменні спори.'
        },
        children: [
            { id: '8.1', text: 'Торговельні марки. Комерційні найменування' },
            { id: '8.2', text: 'Винаходи. Корисні моделі. Промислові зразки. Раціоналізаторські пропозиції.' },
            { id: '8.3', text: 'Географічні зазначення' },
            { id: '8.4', text: 'Авторські та суміжні права.' },
            { id: '8.5', text: 'Доменні спори' },
            { id: '8.6', text: 'Компонування мікросхем' },
            { id: '8.7', text: 'Комерційна таємниця. Конфіденційна інформація. Ексклюзивні дані' },
            { id: '8.8', text: 'Сорти рослин, породи тварин. Інше' },
            { id: '8.9', text: 'Недобросовісна конкуренція у сфері ІВ.' },
            { id: '8.10', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '9',
            text: 'Визнання і виконання рішень іноземних судів; Інші питання правової допомоги в цивільних та комерційних справах.'
        },
        children: [
            { id: '9.1', text: 'Визнання і виконання рішень іноземних судів' },
            { id: '9.2', text: 'Інші питання правової допомоги' },
            { id: '9.3', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '10',
            text: 'Міжнародний арбітраж'
        },
        children: [
            { id: '10.1', text: 'МКАС та інші арбітражі з місцем в Україні' },
            { id: '10.2', text: 'Арбітражі з місцем за кордоном' },
            { id: '10.3', text: 'Визнання та виконання рішень міжнародного арбітражу' },
            { id: '10.4', text: 'Оскарження міжнародних арбітражних рішень ' },
            { id: '10.5', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '11',
            text: 'Спори з третейських угод'
        },
        children: [
            { id: '11.1', text: 'Спори з третейських угод' },
            { id: '11.2', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '12',
            text: 'Спори щодо виконавчого провадження'
        },
        children: [
            { id: '12.1', text: 'Спори щодо виконавчого провадження' },
            { id: '12.2', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '13',
            text: 'Податкові і митні спори'
        },
        children: [
            { id: '13.1', text: 'Спори щодо податку на прибуток' },
            { id: '13.2', text: 'Спори щодо ПДФО' },
            { id: '13.3', text: 'Спори щодо ПДВ' },
            { id: '13.4', text: 'Спори щодо акцизного податку' },
            { id: '13.5', text: 'Спори щодо податку на доходи нерезидентів' },
            { id: '13.6', text: 'Спори щодо трансфертного ціноутворення' },
            { id: '13.7', text: 'Інші податкові спори' },
            { id: '13.8', text: 'Інші митні спори' },
            { id: '13.9', text: 'Спори щодо безтоварних операцій' },
            { id: '13.10', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '14',
            text: 'Антимонопольні спори; Недобросовісна конкуренція; Антидемпінгові спори'
        },
        children: [
            { id: '14.1', text: 'Антимонопольні спори' },
            { id: '14.2', text: 'Спори з питань захисту від недобросовісної конкуренції' },
            { id: '14.3', text: 'Антидемпінгові та аналогічні спори' },
            { id: '14.4', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '15',
            text: 'Регуляторні спори з економічних питань'
        },
        children: [
            { id: '15.1', text: 'Спори щодо вилучення або примусового відчуження майна для суспільних потреб' },
            { id: '15.2', text: 'Інші регуляторні спори з економічних питань' },
            { id: '15.3', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '16',
            text: 'Регуляторні спори в сфері інформації і медіа'
        },
        children: [
            { id: '16.1', text: 'Спори щодо регулювання медіа-ринку' },
            { id: '16.2', text: 'Спори щодо публічної інформації' },
            { id: '16.3', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '17',
            text: 'Спори з питань державного управління та політичних питань'
        },
        children: [
            { id: '17.1', text: 'Спори з приводу публічної служби' },
            { id: '17.2', text: 'Спори між суб’єктами владних повноважень з їхньої компетенції' },
            { id: '17.3', text: 'Інші спори у сфері державного управління' },
            { id: '17.4', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '18',
            text: 'Спори в сфері інформації'
        },
        children: [
            { id: '18.1', text: 'Спори щодо захисту честі, гідності, ділової репутації' },
            { id: '18.2', text: 'Спори щодо персональних даних' },
            { id: '18.3', text: 'Інші спори в сфері інформації' },
            { id: '18.4', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '19',
            text: 'Спори у справах захисту прав споживачів та спори щодо якості продукції'
        },
        children: [
            { id: '19.1', text: 'Спори у справах захисту прав споживачів та спори щодо якості продукції' },
            { id: '19.2', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '20',
            text: 'Трудові спори'
        },
        children: [
            { id: '20.1', text: 'Спори щодо звільнення та поновлення на роботі' },
            { id: '20.2', text: 'Спори щодо оплати та умов праці' },
            { id: '20.3', text: 'Колективні трудові спори' },
            { id: '20.4', text: 'Спори про відшкодування шкоди' }, ,
            { id: '20.5', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '21',
            text: 'Сімейні та спадкові спори'
        },
        children: [
            { id: '21.1', text: 'Сімейні спори' },
            { id: '21.2', text: 'Спадкові спори' }, ,
            { id: '21.3', text: 'Інше' },
        ]
    }, {
        parent: {
            id: '22',
            text: 'Спори про відшкодування шкоди'
        },
        children: [
            { id: '22.1', text: 'Спори щодо відшкодування майнової шкоди' },
            { id: '22.2', text: 'Спори щодо відшкодування немайнової (моральної) шкоди' },
            { id: '22.3', text: 'Справи про підтвердження наявності або відсутності юридичних фактів' },
            { id: '22.4', text: 'Інше' },
        ]
    },
    {
        parent: {
            id: '23',
            text: 'Кримінальне право'
        },
        children: [
            { id: '23.1', text: 'Злочини проти основ національної безпеки України' },
            { id: '23.2', text: 'Злочини проти життя та здоров’я особи' },
            { id: '23.3', text: 'Злочини проти волі, честі та гідності особи' },
            { id: '23.4', text: 'Злочини проти статевої свободи та статевої недоторканості особи' },
            { id: '23.5', text: 'Злочини проти виборчих, трудових та інших особистих прав і свобод особи' },
            { id: '23.6', text: 'Злочини проти власності' },
            { id: '23.7', text: 'Злочини у сфері господарської діяльності' },
            { id: '23.8', text: 'Злочини проти довкілля' },
            { id: '23.9', text: 'Злочини проти громадської безпеки' },
            { id: '23.10', text: 'Злочини проти безпеки виробництва' },
            { id: '23.11', text: 'Злочини проти безпеки руху та експлуатації транспорту' },
            { id: '23.12', text: 'Злочини проти громадського порядку та моральності' },
            { id: '23.13', text: 'Злочини у сфері обігу наркотичних засобів, психотропних речовин тощо та інші злочини проти здоров’я населення' },
            { id: '23.14', text: 'Злочини у сфері охорони державної таємниці, кордонів, забезпечення призову та мобілізації' },
            { id: '23.15', text: 'Злочини проти авторитету органів державної влади, органів місцевого самоврядування, об’єднань громадян та злочини проти журналістів' },
            { id: '23.16', text: 'Злочини у сфері використання електронно-обчислювальних машин (комп’ютерів), систем та комп’ютерних мереж і мереж електрозв’язку' },
            { id: '23.17', text: 'Злочини у сфері службової та професійної діяльності, пов’язаної з наданням публічних послуг' },
            { id: '23.18', text: 'Злочини проти правосуддя' },
            { id: '23.19', text: 'Військові злочини' },
            { id: '23.20', text: 'Злочини проти миру, безпеки людства, та міжнародного правопорядку' },
            { id: '23.21', text: 'Інше' },
        ]
    },
]
export const CASE_TYPES_OLD = [
    {
        id: '1',
        text: 'Спори в сфері інтелектуальної власності'
    }, {
        id: '2',
        text: 'Спори щодо недобросовісної конкуренції (питання інтелектуальної власності)'
    }, {
        id: '3',
        text: 'Спори про захист інформації'
    }, {
        id: '4',
        text: 'Спори щодо доменних імен'
    }, {
        id: '5',
        text: 'Спори у банківській та фінансовій сфері'
    }, {
        id: '6',
        text: 'Податкові та митні спори'
    }, {
        id: '7',
        text: 'Спори з питань власності'
    }, {
        id: '8',
        text: 'Спори з нормативно-правових питань '
    }, {
        id: '9',
        text: 'Спори за цивільно-правовими договорами. Міжнародні комерційні спори за договорами поставки'
    }, {
        id: '10',
        text: 'Спори щодо цінних паперів'
    }, {
        id: '11',
        text: 'Позасудове врегулювання спорів'
    }, {
        id: '12',
        text: 'Спори щодо відшкодування збитків'
    }, {
        id: '13',
        text: 'Корпоративні спори'
    }, {
        id: '14',
        text: 'Інші спори'
    }, {
        id: '15',
        text: 'Сімейні та спадкові спори'
    }, {
        id: '16',
        text: 'Міжнародний арбітраж '
    }, {
        id: '17',
        text: 'Трудові спори'
    }, {
        id: '18',
        text: 'Визнання та виконання рішень іноземних судів. Інша юридична допомога. Спори щодо арбітражних угод'
    }, {
        id: '19',
        text: 'Спори у справах про банкрутство'
    }, {
        id: '20',
        text: 'Спори щодо захисту персональних даних'
    }, {
        id: '21',
        text: 'Спори щодо захисту прав споживачів та якості продукції'
    }, {
        id: '22',
        text: 'Антимонопольні спори. Недобросовісна конкуренція. Антидемпінгові спори'
    }, {
        id: '23',
        text: 'Спори з органами Державної виконавчої служби з питань виконавчого провадження'
    }, {
        id: '24',
        text: 'Спори про захист ділової репутації'
    }, {
        id: '25',
        text: 'Судові накази про примусове виконання'
    }, {
        id: '26',
        text: 'Майнові спори'
    }, {
        id: '27',
        text: 'Питання виконання судових рішень щодо об\'єктів інтелектуальної власності'
    }
] 

export const PROCEEDIN_STAGES = [
    { title: 'Перша', value:'first' },
    { title: 'Друга', value:'second' },
    { title: 'Третя', value:'third' },
]

export const PROCEEDING_STATUSES = [
    {title: 'Скарга розглядається', value: 'active' },
    {title: 'Bирішується питання про відкриття', value: 'wait'},  
    {title: 'Cкарга розглянута', value: 'stopped' },
    {title: 'Oкаржується', value: 'appeal' }
]