import { Directive, Input, HostListener } from '@angular/core';
import { CaseService } from '../services/case.service';

@Directive({
  selector: '[appExcelExport]'
})
export class ExcelExportDirective {
  @Input('appExcelExport') filter: any;
  constructor(
    public caseService: CaseService
  ) {
    console.info(this.filter);
  }

  @HostListener('click', ['$event']) onClick($event) {
    this.caseService.exportExcel(this.filter)
  }

}
