import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DrawerService {
  private drawer$: ReplaySubject<string> = new ReplaySubject(null);
  private drawerData$: ReplaySubject<any> = new ReplaySubject(null);
  constructor() {}

  public getDrawer() {
    return this.drawer$.asObservable();
  }

  public onDrawerShow(type: "side") {
    this.drawer$.next(type);
  }

  public getDrawerData() {
    return this.drawerData$.asObservable();
  }
  public passDrawerData(data: any) {
    this.drawerData$.next(data);
  }
  public onClose() {
    this.drawer$.next(null);
  }
}
