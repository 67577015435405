import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CourtService } from 'src/app/services/court.service';
import { FormService } from 'src/app/services/form.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-court',
  templateUrl: './edit-court.component.html',
  styleUrls: ['./edit-court.component.less']
})
export class EditCourtComponent implements OnInit {
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public courtForm: FormGroup;
  public court;
  constructor(
    public courtService: CourtService,
    public formService: FormService
  ) {
    this.courtForm = this.formService.getCourtGroup();
  }

  ngOnInit(): void {
    if (this.data._id) {
      this.courtService.findByID(this.data._id).subscribe(court => {
        this.court = court;
        this.courtForm = this.formService.getCourtGroupWithData(court);
      })
    }
  }

  public closeModal() {
    this.close.emit();
  }

  public save() {
    this.courtService.update(this.court._id, this.courtForm.value).subscribe(court => {
      this.closeModal();
    })
  }

  public create() {
    this.courtService.create(this.courtForm.value).subscribe(court => {
      this.closeModal();
    })
  }

}
