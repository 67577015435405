import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { API } from '../data';
import { Observable } from 'rxjs';
import { CourtModel } from '../models/court';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class CourtService {

  constructor(
    public http: HttpService,
    public routerService: RouterService
  ) { }

  public find(): Observable<CourtModel[]> {
    return this.http.get<CourtModel[]>(API.COURT);
  }
  public findByID(id: string): Observable<CourtModel> {
    return this.http.get<CourtModel>(`${API.COURT}/${id}`);
  }
  public findByName(name): Observable<CourtModel[]> {
    return this.http.get(`${API.COURT}/find?name=${name}`)
  }

  public update(id: string, court: CourtModel) {
    return this.http.update(API.COURT, id, court)
  }

  public create(court: CourtModel) {
    return this.http.post(API.COURT, court)
  }

  public delete(id: string): Observable<CourtModel> {
    return this.http.delete(API.COURT, id)
  }

  public goToIndex() {
    this.routerService.navigate(['/courts'])
  }
}
