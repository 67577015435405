import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ModalService } from "src/app/services/modal.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CaseService } from "src/app/services/case.service";
import { RouterService } from "src/app/services/router.service";
import { ClaimService } from "../../../services/claim.service";

@Component({
  selector: "app-archive-modal",
  templateUrl: "./archive-modal.component.html",
  styleUrls: ["./archive-modal.component.less"],
})
export class ArchiveModalComponent implements OnInit {
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public form: FormGroup;
  constructor(
    public modalService: ModalService,
    public router: RouterService,
    public caseService: CaseService,
    public claimService: ClaimService
  ) {
    this.form = new FormGroup({
      archive_reason: new FormControl("", Validators.required),
      archive_comment: new FormControl(""),
      archive: new FormControl(true),
    });
  }

  ngOnInit(): void {}

  public closeModal() {
    this.modalService.closeModal();
  }

  public addToArchive() {
    if (this.data.claim) {
      this.claimService
        .addToArchive(this.data.claim, this.form.value)
        .subscribe((res) => {
          this.router.navigate(["/case", this.data._id]);
          this.closeModal();
        });
      return false;
    }
    this.caseService
      .addToArchive(this.data._id, this.form.value)
      .subscribe((res) => {
        this.router.navigate(["/"]);
        this.closeModal();
      });
  }
}
