import { Injectable } from "@angular/core";
import * as msal from "msal";
import { HttpService } from "./http.service";
import { API } from "../data";
import { BehaviorSubject } from "rxjs";
import { RouterService } from "./router.service";
import { UserService } from "./user.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public redirect_url: string;
  public jwt$: BehaviorSubject<any> = new BehaviorSubject(false);
  private requestScope = {
    scopes: ["user.read", "Calendars.ReadWrite", "offline_access"],
  };
  private msalConfig = {
    auth: {
      // clientId: "c5890058-111b-4bd2-a987-76383bc66191",
      // authority: "https://login.microsoftonline.com/4b5078be-4d2f-466e-b7d0-1cd450374673",
      // redirectURI: "http://localhost:3000/admin"
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };

  public MSALObj: any;
  constructor(
    public http: HttpService,
    // public router: RouterService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.jwt$.next(window.localStorage.getItem("token") || false);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.redirect_url = params.redirect_url || "/";
    });
    this.loadAuthData();
  }

  public loadAuthData() {
    this.loadOauthConfig();
  }
  public getJwt() {
    return this.jwt$.asObservable();
  }

  public logout() {
    window.localStorage.clear();
    this.router.navigate(["/logout"]);
    (window.msal as any).logout();
  }

  public loadOauthConfig() {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onload = () => {
      const data = JSON.parse(xhr.response);
      this.msalConfig.auth = data;
      this.MSALObj = new msal.UserAgentApplication(
        this.msalConfig as msal.Configuration
      );

      this.MSALObj.handleRedirectCallback((error, response) => {
        console.log({ error }, { response });
      });
    };
    xhr.open("GET", "/auth-data");
    xhr.send();
  }

  public openLogin() {
    window.localStorage.clear();
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onload = () => {
      const data = JSON.parse(xhr.response);
      this.msalConfig.auth = data;
      console.log(this.msalConfig);
      this.MSALObj = new msal.UserAgentApplication(
        this.msalConfig as msal.Configuration
      );

      this.MSALObj.handleRedirectCallback((error, response) => {
        console.log({ error }, { response });
      });
      this.MSALObj.loginRedirect(this.requestScope);
      // .then((result) => {
      //   const account = this.MSALObj.getAccount();
      //   this.loginUser({
      //     accountIdentifier: account.accountIdentifier,
      //     name: account.name,
      //     userName: account.userName,
      //   });
      // })
      // .catch((err) => {
      //   console.error(err);
      // });
    };
    xhr.open("GET", "/auth-data");
    xhr.send();
  }

  public loginUser(payload) {
    this.http.post(API.AUTH, payload).subscribe((jwt: any) => {
      this.jwt$.next(jwt.jwt);
      window.localStorage.setItem("token", jwt.jwt);
      this.router.navigateByUrl(this.redirect_url);
    });
  }

  public onAuth(token) {
    this.jwt$.next(token);
    window.localStorage.setItem("token", token);
    this.router.navigateByUrl("/");
  }

  public getToken() {
    return window.localStorage.getItem("token");
  }

  public isLogin() {
    return window.localStorage.getItem("token") || false;
  }

  public singleLogin() {
    this.http.get("/auth-data").subscribe((data: any) => {
      console.log(data);
      // window.location.href = `https://login.microsoftonline.com/${data.terrandId}/oauth2/v2.0/authorize?client_id=${data.clientId}&scope=user.read`;
      window.location.href = `https://login.microsoftonline.com/${data.terrandId}/oauth2/v2.0/authorize?client_id=${data.clientId}&response_type=code&redirect_uri=http://localhost:3000/oauth&response_mode=query&scope=https://graph.microsoft.com/User.Read`;
    });
  }
}
