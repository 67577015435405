import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
})
export class LoginComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public title: Title,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        this.authService.onAuth(params.token);
      } else {
        this.login();
      }
    });
  }

  ngOnInit() {
    this.title.setTitle("Login | Aequo Cases");
    window.localStorage.clear();
    // setTimeout(() => {
    //   this.authService.openLogin();
    // }, 1000);
  }

  public login() {
    // this.authService.openLogin();
    window.location.href = "/auth/login/office";
  }

  public singeLogin() {
    // this.authService.singleLogin();
  }
}
