import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterContentInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { CaseModel } from "src/app/models/case";
import { MODAL, PROCEEDING_STATUSES } from "src/app/data";
import { ModalService } from "src/app/services/modal.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { CaseService } from "src/app/services/case.service";
import { DrawerService } from "src/app/services/drawer.service";

declare const $: any;

@Component({
  selector: "app-case-table",
  templateUrl: "./case-table.component.html",
  styleUrls: ["./case-table.component.less"],
})
export class CaseTableComponent implements OnInit, OnChanges, OnDestroy {
  public PROCEEDING_STATUSES = {};
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() cases: any[] = [];
  @Input() loaded: boolean = true;
  public updateTable$: Subscription;
  public dataSource: MatTableDataSource<CaseModel[]>;
  public emptyCol = ["empty"];
  public sticky = window.innerWidth > 768;
  public displayedColumns = [
    "title",
    "sides",
    "court",
    "tasks",
    "subject",
    "procedural",
    "client",
    "team",
  ];
  public length = [];
  constructor(
    public modalService: ModalService,
    public datePipe: DatePipe,
    public caseService: CaseService,
    public drawerService: DrawerService
  ) {
    PROCEEDING_STATUSES.forEach((status) => {
      this.PROCEEDING_STATUSES[status.value] = status.title;
    });
    this.updateTable$ = this.caseService.onCasesUpdate().subscribe((result) => {
      this.cases.forEach((caseObj: CaseModel) => {
        if (caseObj._id === result._id) {
          caseObj.claimant = result.claimant;
          caseObj.defendant = result.defendant;
          caseObj.third_side = result.third_side;
        }
      });
      this.dataSource = new MatTableDataSource(this.cases);
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.cases);
    this.dataSource.paginator = this.paginator;
    // this.favorites = this.caseService.getFavorites$().subscribe(list => {
    //   console.log(list)
    // })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cases) {
      this.dataSource = new MatTableDataSource(changes.cases.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnDestroy(): void {
    this.updateTable$.unsubscribe();
  }

  public getTaskDate(date) {
    let hours = new Date(date).getHours();
    if (hours > 0) {
      return this.datePipe.transform(date, "dd.MM.yy, HH:mm");
    } else {
      return this.datePipe.transform(date, "dd.MM.yy");
    }
  }
  public openEvent(type, id) {
    this.modalService.openModal({
      type: MODAL.CALENDAR,
      data: {
        id: id,
        type: type,
      },
    });
  }

  public openCourtModal(id) {
    this.modalService.openModal({
      type: MODAL.COURT,
      data: {
        _id: id,
        action: "view",
      },
    });
  }

  public isTomorrow(date) {
    let now = new Date();
    let event_date = new Date(date);
    let tomorrow = `${now.getDate() + 1}.${now.getMonth()}`;
    return (
      tomorrow == `${event_date.getDate()}.${event_date.getMonth()}` ||
      `${now.getDate()}.${now.getMonth()}` ==
        `${event_date.getDate()}.${event_date.getMonth()}`
    );
  }

  public openClientModal(is_client, number) {
    if (!is_client) {
      return false;
    }
    this.modalService.openModal({
      type: MODAL.CLIENT,
      data: {
        number: number,
      },
    });
  }

  public getPages() {
    const arr = new Array(this.cases.length).fill("");

    return arr.length;
  }

  public changeTablePAge() {
    let app = document.getElementById("application");
    app.scrollTo(0, 0);
  }
  public caseInFavorites(case_id) {
    return this.caseService.caseInFavorite(case_id);
  }

  public addToFavorites(id) {
    this.caseService.addToFavorites(id);
  }
  public removeFromFavorites(id) {
    this.caseService.deleteFavorites(id);
  }

  public getProceduralTooltip(item) {
    return `${item.justice_name ? item.justice_name : ""}${
      item.court_name ? ", " + item.court_name : ""
    }`;
  }

  public onSideAnalyze(caseData, side, type) {
    this.drawerService.onDrawerShow("side");
    this.drawerService.passDrawerData({
      ...side,
      type,
      caseId: caseData._id,
      lexiaNumbder: side.client ? caseData.lexia : null,
    });
  }
}
