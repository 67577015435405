import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DrawerService } from "src/app/services/drawer.service";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.less"],
})
export class DrawerComponent implements OnInit, OnDestroy {
  public drawer$: Subscription;
  public drawer: string;
  constructor(private drawerService: DrawerService) {
    this.drawer$ = this.drawerService.getDrawer().subscribe((drawer) => {
      this.drawer = drawer;
    });
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.drawer$.unsubscribe();
  }

  onClose() {
    this.drawerService.onClose();
  }
}
