import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { API } from '../data';
import { Observable } from 'rxjs';
import { CaseModel } from '../models/case';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    public http: HttpService
  ) { }

  public search(query): Observable<any> {
    return this.http.get('/search?query=' + query)
  }
}
