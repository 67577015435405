import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CommentsService } from 'src/app/services/comments.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less']
})
export class CommentsComponent implements OnInit {
  @Input() target_id: string;
  public commentChildren: string = '';
  public deleteComment: string = '';
  public parentCommentID: string;
  public user: any;
  public comment: string = '';
  public comments = [];


  constructor(
    public userService: UserService,
    public commentService: CommentsService
  ) {
    this.userService.getProfile().subscribe(profile => {
      this.user = profile;
    })
  }

  ngOnInit(): void {
    this.loadComments();
  }


  public loadComments() {
    this.commentService.getComments(this.target_id).subscribe((comments: any) => {
      this.comments = comments;
      this.comment = '';
      this.commentChildren = '';
    })
  }
  public addComment() {
    this.commentService.addComment({
      comment: this.comment,
      target_id: this.target_id,
      parent: null
    }, this.target_id).subscribe(res => {
      this.loadComments();
    })
  }

  public addChildComment() {
    this.commentService.addComment({
      comment: this.commentChildren,
      target_id: this.target_id,
      parent: this.parentCommentID
    }, this.target_id).subscribe(res => {
      this.loadComments();
    })
  }

  public setParentComment(id) {
    this.parentCommentID = id;
    this.commentChildren = '';
    setTimeout(() => {
      document.getElementById("commentChild").focus();
    }, 150)
  }
  public setDeleteComment(id) {
    this.deleteComment = id;
  }

  public removeComment(id) {
    this.commentService.deleteComment(id).subscribe(() => {
      this.loadComments();
    })
  }

}
