import { Injectable } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { CourtModel } from "../models/court";
import { UserModel } from "../models/user";
import { OptionModel } from "../models/option";
import { JudgeModel } from "../models/judge";
import { CaseModel } from "../models/case";
import { TaskModel } from "../models/task";
import { EventModel } from "../models/event";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor() {}

  public getProceduralGroup() {
    return new FormGroup({
      adjudication_date: new FormControl("", Validators.required),
      justice_name: new FormControl("", Validators.required),
      justice_code: new FormControl("", Validators.required),
      title: new FormControl(""),
      link: new FormControl(null),
      comment: new FormControl(""),
      case_number: new FormControl("", Validators.required),
      claim: new FormControl(null),
    });
  }
  public getProceduralGroupWithData(item: any) {
    return new FormGroup({
      _id: new FormControl(item._id),
      adjudication_date: new FormControl(
        item.adjudication_date,
        Validators.required
      ),
      justice_name: new FormControl(item.justice_name, Validators.required),
      justice_code: new FormControl(item.justice_code, Validators.required),
      title: new FormControl(item.title),
      link: new FormControl(item.link),
      comment: new FormControl(item.comment),
      case_number: new FormControl(item.case_number, Validators.required),
      claim: new FormControl(item.claim || null),
    });
  }
  public getEventGroup() {
    return new FormGroup({
      case: new FormControl("", Validators.required),
      court: new FormControl("", Validators.required),
      date_start: new FormControl(null, Validators.required),
      date_end: new FormControl(null, Validators.required),
      comment: new FormControl(""),
      user: new FormControl(null),
      claim: new FormControl(null),
    });
  }
  public getEventGroupWithData(event: EventModel) {
    const eventClaim = event.claim || null;
    console.log({ eventClaim, event });
    const form = new FormGroup({
      _id: new FormControl(event._id),
      case: new FormControl(event.case, Validators.required),
      court: new FormControl(event.court._id, Validators.required),
      date_start: new FormControl(event.date_start, Validators.required),
      date_end: new FormControl(event.date_end),
      comment: new FormControl(event.comment),
      user: new FormControl(event.user),
      claim: new FormControl(event.claim || null),
    });
    console.log({ form });
    return form;
  }
  public getTaskGroup() {
    return new FormGroup({
      title: new FormControl("", Validators.required),
      text: new FormControl(""),
      case: new FormControl(""),
      date_start: new FormControl("", Validators.required),
      responsible: new FormControl("", Validators.required),
      status: new FormControl("active"),
      claim: new FormControl(null),
    });
  }
  public getTaskGroupWithData(task: TaskModel) {
    return new FormGroup({
      _id: new FormControl(task._id),
      case: new FormControl(task.case),
      title: new FormControl(task.title, Validators.required),
      text: new FormControl(task.text),
      date_start: new FormControl(task.date_start, Validators.required),
      responsible: new FormControl(task.responsible._id, Validators.required),
      claim: new FormControl((task as any).claim ? (task as any).claim : null),
    });
  }
  public getCaseGroup() {
    return new FormGroup({
      number: new FormControl("", Validators.required),
      title: new FormControl("", Validators.required),
      status: new FormControl("active", Validators.required),
      court: new FormControl(""),
      practice: new FormControl(null),
      industry: new FormControl([]),
      writing: new FormControl(false),
      without_number: new FormControl(false),
      judges: new FormControl([]),
      courts: new FormArray([]),
      open_date: new FormControl("", Validators.required),
      private: new FormControl(false),
      current_status: new FormControl(""),
      subject: new FormControl("", Validators.required),
      circumstances: new FormControl(""),
      reason: new FormControl(""),
      goal: new FormControl(""),
      claimant: new FormArray([]),
      defendant: new FormArray([]),
      third_side: new FormArray([]),
      team: new FormArray([], Validators.required),
      responsible: new FormControl("", Validators.required),
      judgment_code: new FormControl("", Validators.required),
      instance: new FormControl("", Validators.required),
      type: new FormArray([this.getCaseTypeGroup()], Validators.required),
      lexia_client: new FormControl("", Validators.required),
      lexia_contact: new FormControl(""),
      lexia_project: new FormControl(""),
      lexia: new FormControl(""),
      sharepoint: new FormControl(""),
      is_lexia: new FormControl(true),
      cases: new FormControl([]),
      grounds_complaint: new FormControl(""),
      law_client: new FormControl(""),
      law_opponent: new FormControl(""),
      notes: new FormControl(""),
      main_judge: new FormControl(""),
      proceeding: new FormControl(""),
      case_type: new FormControl("case"),
    });
  }

  public getCaseTypeGroup() {
    return new FormGroup({
      parent: new FormControl("", Validators.required),
      children: new FormControl([]),
    });
  }
  public getCaseTypeGroupWithData(data) {
    return new FormGroup({
      parent: new FormControl(data.parent, Validators.required),
      children: new FormControl(data.children),
    });
  }

  public getLexiaProjectGroup() {
    return new FormGroup({
      Name: new FormControl("", Validators.required),
      Phone: new FormControl("", Validators.required),
      Email: new FormControl("", Validators.required),
      PersonName: new FormControl("", Validators.required),
    });
  }

  public getCaseGroupWithData(caseObj: CaseModel) {
    const group = new FormGroup({
      number: new FormControl(caseObj.number, Validators.required),
      title: new FormControl(caseObj.title, Validators.required),
      status: new FormControl(caseObj.status, Validators.required),
      court: new FormControl(caseObj.court),
      practice: new FormControl(null),
      industry: new FormControl(caseObj.industry),
      writing: new FormControl(caseObj.writing),
      without_number: new FormControl(caseObj.without_number),
      judges: new FormControl(caseObj.judges),
      open_date: new FormControl(caseObj.open_date),
      private: new FormControl(caseObj.private),
      current_status: new FormControl(caseObj.current_status),
      subject: new FormControl(caseObj.subject),
      circumstances: new FormControl(caseObj.circumstances),
      reason: new FormControl(caseObj.reason),
      goal: new FormControl(caseObj.goal),
      team: new FormArray([], Validators.required),
      responsible: new FormControl(caseObj.responsible, Validators.required),
      claimant: new FormArray([]),
      defendant: new FormArray([]),
      third_side: new FormArray([]),
      judgment_code: new FormControl(
        caseObj.judgment_code,
        Validators.required
      ),
      instance: new FormControl(caseObj.instance, Validators.required),
      type: new FormArray([], Validators.required),
      lexia_contact: new FormControl(caseObj.lexia_contact),
      lexia: new FormControl(caseObj.lexia),
      lexia_client: new FormControl(caseObj.lexia_client),
      lexia_project: new FormControl(caseObj.lexia_project),
      is_lexia: new FormControl(caseObj.is_lexia),
      sharepoint: new FormControl(caseObj.sharepoint),
      courts: new FormArray([]),
      cases: new FormControl(caseObj.cases),
      grounds_complaint: new FormControl(caseObj.grounds_complaint),
      law_client: new FormControl(caseObj.law_client),
      law_opponent: new FormControl(caseObj.law_opponent),
      notes: new FormControl(caseObj.notes),
      main_judge: new FormControl(caseObj.main_judge),
      proceeding: new FormControl(caseObj.proceeding ? caseObj.proceeding : ""),
      case_type: new FormControl(
        caseObj.case_type ? caseObj.case_type : "case"
      ),
    });

    caseObj.type.forEach((type) => {
      (group.controls.type as FormArray).push(
        this.getCaseTypeGroupWithData(type)
      );
    });
    caseObj.team.forEach((side) => {
      (group.controls.team as FormArray).push(new FormControl(side));
    });
    caseObj.claimant.forEach((side) => {
      (group.controls.claimant as FormArray).push(
        this.getCaseSideWidthData(side)
      );
    });
    caseObj.defendant.forEach((side) => {
      (group.controls.defendant as FormArray).push(
        this.getCaseSideWidthData(side)
      );
    });
    caseObj.third_side.forEach((side) => {
      (group.controls.third_side as FormArray).push(
        this.getCaseSideWidthData(side)
      );
    });

    if (caseObj.courts) {
      caseObj.courts.forEach((court) => {
        (group.controls.courts as FormArray).push(
          this.getCaseCourtGroup(court)
        );
      });
    }

    return group;
  }

  public getCaseCourtGroup(court?) {
    return new FormGroup({
      court: new FormControl(court ? court.court : ""),
      judges: new FormControl(court ? court.judges : []),
    });
  }
  public getCaseSide() {
    return new FormGroup({
      opponent: new FormControl(false),
      name: new FormControl("", Validators.required),
      client: new FormControl(false),
      beneficiary: new FormControl(""),
      agent: new FormControl(""),
      initiator: new FormControl(false),
      code: new FormControl(null),
    });
  }
  public getCaseSideWidthData(data) {
    const side = Object.assign(
      {
        opponent: false,
        name: "",
        client: false,
        beneficiary: "",
        agent: "",
        initiator: false,
        code: null,
      },
      data
    );
    return new FormGroup({
      opponent: new FormControl(side.opponent),
      name: new FormControl(side.name, Validators.required),
      client: new FormControl(side.client),
      beneficiary: new FormControl(side.beneficiary),
      agent: new FormControl(side.agent),
      initiator: new FormControl(side.initiator ? side.initiator : false),
      code: new FormControl(side.code ? side.code : null),
    });
  }

  public getJudgeGroup() {
    return new FormGroup({
      name: new FormControl("", Validators.required),
      status: new FormControl("judge", Validators.required),
      determined: new FormControl(""),
      determined_date: new FormControl(""),
      court: new FormControl(null),
      instance: new FormControl(""),
      email: new FormControl("", Validators.email),
      phone: new FormControl(""),
      assistants: new FormArray([]),
      address: new FormControl(),
      judge: new FormControl(),
      has_family_link: new FormControl(false),
      family: new FormArray([]),
    });
  }
  public getJudgeGroupWithData(judge: JudgeModel | any) {
    let has_family_link = judge.has_family_link || false;
    let group = new FormGroup({
      name: new FormControl(judge.name, Validators.required),
      status: new FormControl(judge.status, Validators.required),
      determined: new FormControl(judge.determined),
      determined_date: new FormControl(judge.determined_date),
      court: new FormControl(judge.court),
      instance: new FormControl(judge.instance),
      email: new FormControl(judge.email, Validators.email),
      phone: new FormControl(judge.phone),
      assistants: new FormArray([]),
      address: new FormControl(judge.address),
      judge: new FormControl(judge.judge),
      family: new FormArray([]),
      has_family_link: new FormControl(has_family_link),
    });
    judge.assistants.forEach((el) => {
      (group.controls.assistants as FormArray).push(new FormControl(el));
    });
    judge.family.forEach((el) => {
      (group.controls.family as FormArray).push(
        this.getFamilyGroupWithData(el)
      );
    });
    return group;
  }
  public getOptionGroup() {
    return new FormGroup({
      title: new FormControl("", Validators.required),
      type: new FormControl("", Validators.required),
    });
  }
  public getOptionGroupWithData(option: OptionModel) {
    return new FormGroup({
      title: new FormControl(option.title, Validators.required),
      type: new FormControl(option.type, Validators.required),
    });
  }
  public getUserGroup() {
    return new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      email_user: new FormControl(""),
      phone: new FormControl(""),
      phone_internal: new FormControl(""),
      ad_id: new FormControl(""),
      photo: new FormControl(""),
      position: new FormControl(""),
      practice: new FormControl(""),
      license_number: new FormControl(""),
      leader: new FormControl(""),
      sharepoint_link: new FormControl(""),
    });
  }

  public getUserGroupWithData(user: UserModel) {
    return new FormGroup({
      name: new FormControl(user.name, Validators.required),
      email: new FormControl(user.email, Validators.required),
      email_user: new FormControl(user.email_user),
      phone: new FormControl(user.phone),
      phone_internal: new FormControl(user.phone_internal),
      photo: new FormControl(user.photo),
      position: new FormControl(user.position),
      practice: new FormControl({ value: user.practice, disabled: false }),
      license_number: new FormControl(user.license_number),
      leader: new FormControl(user.leader),
      sharepoint_link: new FormControl(user.sharepoint_link),
    });
  }
  public getCourtGroup() {
    return new FormGroup({
      name: new FormControl("", Validators.required),
      instance: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      schedule: new FormControl("", Validators.required),
      website: new FormControl("", Validators.required),
    });
  }

  public getCourtGroupWithData(court: CourtModel) {
    return new FormGroup({
      name: new FormControl(court.name, Validators.required),
      instance: new FormControl(court.instance, Validators.required),
      email: new FormControl(court.email, Validators.required),
      phone: new FormControl(court.phone, Validators.required),
      address: new FormControl(court.address, Validators.required),
      schedule: new FormControl(court.schedule, Validators.required),
      website: new FormControl(court.website, Validators.required),
    });
  }

  public getFamilyGroup() {
    return new FormGroup({
      name: new FormControl("", Validators.required),
      works: new FormArray([this.getFamilyWorkGroup()]),
      attrs: new FormControl([]),
      state: new FormControl(""),
    });
  }
  public getFamilyGroupWithData(item) {
    let fg = new FormGroup({
      name: new FormControl(item.name, Validators.required),
      works: new FormArray([]),
      attrs: new FormControl(item.attrs),
      state: new FormControl(item.state ? item.state : ""),
    });
    item.works.forEach((item) => {
      (fg.controls.works as FormArray).push(
        this.getFamilyWorkGroupWIthData(item)
      );
    });
    return fg;
  }

  public getFamilyWorkGroup() {
    return new FormGroup({
      position: new FormControl("", Validators.required),
      work: new FormControl("", Validators.required),
      date_start: new FormControl(""),
      date_end: new FormControl(""),
      work_now: new FormControl(false),
    });
  }
  public getFamilyWorkGroupWIthData(item) {
    return new FormGroup({
      position: new FormControl(item.position, Validators.required),
      work: new FormControl(item.work, Validators.required),
      date_start: new FormControl(item.date_start),
      date_end: new FormControl(item.date_end),
      work_now: new FormControl(item.work_now),
    });
  }
}
