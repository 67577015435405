import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { FormGroup } from "@angular/forms";
import { TaskService } from "src/app/services/task.service";
import { CaseService } from "src/app/services/case.service";
import { EventService } from "src/app/services/event.service";
import { SettingService } from "src/app/services/setting.service";
import { CaseModel } from "src/app/models/case";

@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrls: ["./add-event.component.less"],
})
export class AddEventComponent implements OnInit {
  public show_case: boolean = false;
  public caseObj: CaseModel;
  @Input() data: any = {
    case_number: "",
    action: "create",
    obj: {
      court: "",
    },
  };
  @Output() close: EventEmitter<any> = new EventEmitter();
  public taskForm: FormGroup;
  public eventForm: FormGroup;
  public date_obj = { start: null, end: null };
  public show: boolean = false;
  public cases: any[] = [];
  constructor(
    public formService: FormService,
    public caseService: CaseService,
    public taskService: TaskService,
    public eventService: EventService,
    public settingService: SettingService
  ) {
    this.taskForm = this.formService.getTaskGroup();
    this.eventForm = this.formService.getEventGroup();
  }

  ngOnInit(): void {
    if (this.data.show_case) {
      this.show_case = true;
      this.caseService.getCases().subscribe((list) => {
        this.cases = list;
      });
    }
    if (this.data.type == "event" && this.data.action == "edit") {
      this.date_obj = {
        start: this.data.obj.date_start,
        end: this.data.obj.date_end,
      };
      this.eventForm = this.formService.getEventGroupWithData(this.data.obj);
    }
    if (this.data.type == "task" && this.data.action == "edit") {
      this.date_obj = {
        start: this.data.obj.date_start,
        end: null,
      };
      this.taskForm = this.formService.getTaskGroupWithData(this.data.obj);
      setTimeout(
        () => document.getElementById(this.data.obj.responsible._id).click(),
        400
      );
    }
    if (this.data.case_number) {
      this.taskForm.controls.case.setValue(this.data.case_number);
      this.eventForm.controls.case.setValue(this.data.case_number);
    }
    let caseObj = JSON.parse(window.localStorage.getItem("case"));
    if (
      caseObj &&
      this.data.type == "event" &&
      this.data.action == "create" &&
      (window.location.href.indexOf("/case/") != -1 ||
        window.location.href.indexOf("/proceedings/") != -1)
    ) {
      this.eventForm.controls.court.setValue(caseObj.court._id);
      this.data.obj.court = caseObj.court._id;
    }
    if (this.data.obj && this.data.obj.claim) {
      this.eventForm.controls.claim.setValue(this.data.obj.claim);
    }
    this.show = true;
  }

  public selectCase(case_number) {
    console.log({ case_number });
    this.data.case_number = case_number;
    this.eventForm.controls.court.setValue("");
    let selected_case = this.cases.find((el) => el.number == case_number);
    if (selected_case) {
      setTimeout(() => {
        this.eventForm.controls.court.setValue(selected_case.court._id);
      }, 150);
    }
  }

  public completedEventTime(date) {
    this.eventForm.controls.date_start.setValue(date.start);
    this.eventForm.controls.date_end.setValue(date.end);
  }

  public completedTaskTime(date) {
    this.taskForm.controls.date_start.setValue(date.start);
  }

  public createEvent() {
    this.eventService.create(this.eventForm.value).subscribe((event) => {
      this.caseService.setCaseId(this.data.case_number);
      this.settingService.reloadData("event");
      this.close.emit();
    });
  }
  public updateEvent() {
    this.eventService.update(this.eventForm.value).subscribe((event) => {
      this.caseService.setCaseId(this.data.case_number);
      this.settingService.reloadData("event");
      this.close.emit();
    });
  }
  public createTask() {
    this.taskService.create(this.taskForm.value).subscribe((task) => {
      this.caseService.setCaseId(this.data.case_number);
      this.settingService.reloadData("task");
      this.close.emit();
    });
  }
  public updateTask() {
    this.taskService
      .update(this.taskForm.controls._id.value, this.taskForm.value)
      .subscribe((task) => {
        this.caseService.setCaseId(this.data.case_number);
        this.settingService.reloadData("task");
        this.close.emit();
      });
  }
  public closeModal() {
    this.close.emit();
  }

  public onClaimChange(claimId) {
    this.eventForm.controls.claim.setValue(claimId);
  }
}
