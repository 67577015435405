import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CaseService } from "src/app/services/case.service";
import { EventService } from "src/app/services/event.service";
import { SettingService } from "src/app/services/setting.service";

@Component({
  selector: "app-transfer-modal",
  templateUrl: "./transfer-modal.component.html",
  styleUrls: ["./transfer-modal.component.less"],
})
export class TransferModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  public caseObj: any;

  constructor(
    private caseService: CaseService,
    public settingService: SettingService,
    public eventService: EventService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadCase();
  }
  public closeModal() {
    this.settingService.reloadData("procedural");
    this.settingService.reloadData("event");
    this.close.emit();
  }

  public loadCase() {
    this.caseService.getCaseData(this.data.caseId).subscribe((data) => {
      this.caseObj = data;
    });
  }
  public onTransfer(claimId) {
    if (this.data.type == "history") {
      this.caseService
        .updateProcedural({
          _id: this.data.itemId,
          claim: claimId,
          marker: "hide",
        })
        .subscribe(() => {
          this.closeModal();
        });
    }
    if (this.data.type == "event") {
      this.eventService
        .update({
          _id: this.data.itemId,
          claim: claimId,
          marker: "hide",
        })
        .subscribe(() => {
          this.closeModal();
        });
    }
  }

  public onStay() {
    if (this.data.type == "history") {
      this.caseService
        .updateProcedural({
          _id: this.data.itemId,
          claim: null,
          marker: "hide",
        })
        .subscribe(() => {
          this.closeModal();
        });
    }

    if (this.data.type == "event") {
      this.eventService
        .update({
          _id: this.data.itemId,
          claim: null,
          marker: "hide",
        })
        .subscribe(() => {
          this.closeModal();
        });
    }
  }
}
