import {
  Component,
  OnInit,
  forwardRef,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CaseService } from "src/app/services/case.service";

export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CaseSelectComponent),
  multi: true,
};

@Component({
  selector: "app-case-select",
  templateUrl: "./case-select.component.html",
  styleUrls: ["./case-select.component.less"],
  providers: [COMPONENT_NAME_VALUE_ACCESSOR],
})
export class CaseSelectComponent implements OnInit {
  @Input() showClaims = true;
  @Input() claimId: string = null;
  @Input() input: string | any[];
  @Output() selected: EventEmitter<any> = new EventEmitter();
  private _value: any;
  public show: boolean = false;
  public cases: any[] = [];
  public text_value: string = "";
  public search: string = "";
  public search_result: any[] = [];
  public selectItemCase = null;
  constructor(private eRef: ElementRef, public caseService: CaseService) {}

  ngOnInit(): void {
    this.caseService.getCases().subscribe((cases) => {
      this.cases = cases;
      this.search_result = cases;
      if (this.input) {
        const tempValue = this.cases.find((el) => el.number == this.input);
        console.log(this.input, { claim: this.claimId }, { tempValue });
        if (tempValue) this.selectItem(tempValue);
      }
    });
  }

  public searchCase(search_str) {
    if (search_str) {
      this.search_result = this.cases.filter(
        (c) => c.title.toUpperCase().indexOf(search_str.toUpperCase()) != -1
      );
    } else {
      this.search_result = this.cases;
    }
  }

  public selectItem(item) {
    this.selectItemCase = item;
    this.text_value = item.title;
    this.setValue(item.number);
    this.selected.emit(item.number);
    this.close();
  }

  public selectClaim(claimId) {
    this.selected.emit({ number: this.selectItemCase.number, claimId });
  }

  public open() {
    this.show = true;
  }
  public close() {
    this.show = false;
  }
  public toggle() {
    this.show = !this.show;
  }

  public setValue(value) {
    this._value = value;
    this.notifyValueChange();
  }

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): any {
    return this._value;
  }
  onChange: (value) => {};
  onTouched: () => {};
  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // this.toggle();
    } else {
      this.close();
    }
  }
}
