import { Component, OnInit, Input } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-case-files',
  templateUrl: './case-files.component.html',
  styleUrls: ['./case-files.component.less']
})
export class CaseFilesComponent implements OnInit {
  @Input() case_id: string;
  public files: any[] = [];
  public uploadingFiles = [];
  constructor(
    public caseService: CaseService
  ) { }

  ngOnInit(): void {
    this.loadFiles(this.case_id);
  }


  public loadFiles(case_id) {
    this.caseService.getFiles(case_id).subscribe(files => {
      this.files = files;
    })
  }
  public changeFiles(event) {
    let files = event.target.files;
    this.uploadingFiles = files;
    this.caseService.uploadFiles(this.case_id, files).subscribe(files => {
      this.uploadingFiles = [];
      this.loadFiles(this.case_id);
    })
  }

  public getFileExt(name) {
    return name.split('.').reverse()[0];
  }

  public getFileSize(size) {
    return `${(size / 1000 / 1000).toFixed(2)} Mb`
  }

  public deleteFile(file_id) {
    this.caseService.deleteFile(file_id).subscribe(file => {
      this.loadFiles(this.case_id)
    })
  }
}
