import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class ClaimService {
  constructor(private http: HttpService) {}

  public create(claim) {
    return this.http.post("/claim", claim);
  }
  public update(claimId, claim) {
    return this.http.update("/claim", claimId, claim);
  }
  public getClaim(id) {
    return this.http.get("/claim/" + id);
  }
  public getClaimsByCase(number) {
    return this.http.get("/claim/case?number=" + number);
  }
  public addToArchive(id, data) {
    return this.http.post(`/claim/archive/${id}`, data);
  }
  public removeFromArchive(id) {
    return this.http.get(`/claim/unarchive/${id}`);
  }
}
