import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { CaseService } from "src/app/services/case.service";
import { DrawerService } from "src/app/services/drawer.service";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-case-side-analyze",
  templateUrl: "./case-side-analyze.component.html",
  styleUrls: ["./case-side-analyze.component.less"],
})
export class CaseSideAnalyzeComponent implements OnInit, OnDestroy {
  public drawerData$: Subscription;
  public data: any;
  public codeControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(10),
    Validators.pattern(/^\s*-?[0-9]{8,10}\s*$/),
  ]);
  public registerData: any;
  public lexiaProject: any;
  public loading = true;
  public isClaim = false;
  constructor(
    public drawerService: DrawerService,
    public caseService: CaseService,
    private http: HttpService
  ) {
    this.drawerData$ = this.drawerService.getDrawerData().subscribe((data) => {
      console.log({ data });
      this.codeControl.reset();
      this.registerData = null;
      this.data = data;
      this.codeControl.setValue(data.code || null);
      this.isClaim = data.claim || false;
      if (data.code) {
        this.getRegisterData(data.code);
      } else {
        this.loading = false;
      }
      if (data.lexiaNumbder) {
        this.http
          .get(`/lexia/projects/${this.data.lexiaNumbder}`)
          .subscribe((project) => {
            this.lexiaProject = project;
          });
      } else {
        this.lexiaProject = null;
      }
    });
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.drawerData$.unsubscribe();
  }

  public onCodeSet() {
    if (!this.codeControl.valid) {
      return;
    }
    this.loading = true;
    this.codeControl.disable();
    this.caseService
      .setCaseSideCode(
        this.data.caseId,
        this.data._id,
        this.codeControl.value,
        this.data.type,
        this.isClaim
      )
      .subscribe((result) => {
        this.caseService.updateCases$.next(result);
        this.codeControl.enable();
        this.drawerService.passDrawerData({
          ...this.data,
          code: this.codeControl.value,
        });
        this.getRegisterData(this.codeControl.value);
      });
  }

  public getRegisterData(code, update = 0) {
    this.loading = true;
    this.caseService.getCaseSideData(code, update).subscribe((data) => {
      this.registerData = data;
      this.loading = false;
    });
  }

  public onUpdate() {
    this.getRegisterData(this.codeControl.value, 1);
  }

  public closeModal() {
    this.drawerService.onClose();
  }
}
