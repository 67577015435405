import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { API } from '../data';
import { Observable } from 'rxjs';
import { OptionModel } from '../models/option';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor(
    public http: HttpService,
    public routerService: RouterService
  ) { }

  public find(): Observable<OptionModel[]> {
    return this.http.get<OptionModel[]>(API.OPTION)
  }
  public findByID(id: string): Observable<OptionModel> {
    return this.http.get<OptionModel>(`${API.OPTION}/${id}`)
  }
  public findByType(type: string): Observable<OptionModel[]> {
    return this.http.get<OptionModel[]>(`${API.OPTION}/get/${type}`)
  }
  public update(id: string, option: OptionModel): void {
    this.http.update(API.OPTION, id, option).subscribe(option => {
      this.goToIndex();
    })
  }
  public delete(id: string): Observable<OptionModel> {
    return this.http.delete(API.OPTION, id)
  }
  public create(option: OptionModel): void {
    this.http.post<OptionModel>(API.OPTION, option).subscribe(option => {
      this.goToIndex();
    })
  }

  public goToIndex() {
    this.routerService.navigate(['/options'])
  }
}
