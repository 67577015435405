import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.less']
})
export class BackButtonComponent implements OnInit {
  public length: number;
  constructor() {
    this.length = window.history.length;
  }

  ngOnInit(): void {
  }

  public back() {
    window.history.back();
  }

}
