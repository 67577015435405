import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { CaseService } from 'src/app/services/case.service';
import { SettingService } from 'src/app/services/setting.service';
import { TaskService } from 'src/app/services/task.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.less']
})
export class RemoveModalComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  constructor(
    public modalService: ModalService,
    public caseService: CaseService,
    public settingService: SettingService,
    public taskService: TaskService,
    public eventService: EventService
  ) { }

  ngOnInit(): void { 
  }

  public closeModal() {
    this.close.emit();
  }

  public remove() {
    switch (this.data.type) {
      case 'procedural':
        this.caseService.deleteProcedural(this.data._id).subscribe(deleted => {
          this.settingService.reloadData('procedural')
        })
        break;
      case 'task':
        this.taskService.delete(this.data._id).subscribe(task => {
          this.settingService.reloadData('task')
        })
        break;
      case 'event':
        this.eventService.delete(this.data._id).subscribe(task => {
          this.settingService.reloadData('event')
        });
        break;
      case 'update_procedural':
        this.caseService.updateProceduralFromAPI(this.data._id).subscribe(data => {
          this.settingService.reloadData('procedural')
        })
        break;
      case 'update_event':
        this.caseService.updateEventsFromAPI(this.data._id).subscribe(res => {
          this.settingService.reloadData('event')
        })
        break;
      case 'update_case':
        this.caseService.updateCaseFromAPI(this.data._id).subscribe(res => {
          this.settingService.reloadData('case')
          this.settingService.reloadData('procedural')
        })
        break;
      case 'toggle_field':
        this.caseService.saveCaseSetting(this.data._id, this.data.field, this.data.value)
        break;
    };
    this.close.emit();
  }

  public isUpdate() {
    if (!this.data.type) {
      return false;
    }
    return ['update_procedural', 'update_event', 'update_case'].indexOf(this.data.type) != -1;
  }

}
