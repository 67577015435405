import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.less']
})
export class ClientModalComponent implements OnInit {
  @Input() data: any
  @Output() close: EventEmitter<any> = new EventEmitter();
  public project: any;
  constructor(
    public httpService: HttpService
  ) { }

  ngOnInit(): void { 
    if (this.data.number) {
      this.httpService.get(`/lexia/projects/${this.data.number}`).subscribe(project => {
        this.project = project;
      })
    }
  }

  public closeModal() {
    this.close.emit();
  }

}
