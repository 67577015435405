export class SettingModel {
    showFilter: boolean = false;
    filterData: FilterDataModel = new FilterDataModel();
}

export class FilterDataModel {
    public industry: string[] = [];
    public users_type: string = 'team';
    public users: string[] = [];
    public period_type: string = 'range';
    public period_start: string = '';
    public period_end: string = '';
    public courts: string[] = [];
    public instance: string[] = [];
    public type: any[] = [{
        parent: '',
        children: []
    }];
    public judgment_code: any[] = [];
    public writing: boolean = false;
    public stopped: boolean = false;
    public events: boolean = false;
    public show_drop: boolean = false;
    public judge: string = '';
    public archive: boolean = false;
    public events_type: string[] = ['event', 'task'];
    public lexia_clients: string[] = [];
}