import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EventService } from "src/app/services/event.service";
import { TaskService } from "src/app/services/task.service";
import { ModalService } from "src/app/services/modal.service";
import { MODAL } from "src/app/data";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-calendar-modal",
  templateUrl: "./calendar-modal.component.html",
  styleUrls: ["./calendar-modal.component.less"],
})
export class CalendarModalComponent implements OnInit {
  public loaded: boolean = false;
  public event: any;
  public task: any;
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public client_status: string = "";
  constructor(
    public eventService: EventService,
    public modalService: ModalService,
    public taskService: TaskService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.data.type == "event") {
      this.eventService.findOne(this.data.id).subscribe((event: any) => {
        if (event.claim) {
          Object.assign(event.case, event.claim);
        }
        console.log({ event });
        this.event = event;
        event.case.claimant.forEach((s) => {
          if (s.client) {
            this.client_status = "позивач";
          }
        });
        event.case.defendant.forEach((s) => {
          if (s.client) {
            this.client_status = "відповідач";
          }
        });
        event.case.third_side.forEach((s) => {
          if (s.client) {
            this.client_status = "третя особа";
          }
        });
        this.loaded = true;
      });
    }
    if (this.data.type == "task") {
      this.taskService.findByID(this.data.id).subscribe((task) => {
        this.task = task;
      });
    }
  }

  public closeModal() {
    this.close.emit();
  }

  public completeTask(id) {
    this.taskService.completeTask(id);
    this.close.emit();
  }
  public openEvent(type, id) {
    this.data.type = type;
    this.data.id = id;
    this.ngOnInit();
  }
  public getTaskDate(date) {
    let hours = new Date(date).getHours();
    if (hours > 0) {
      return this.datePipe.transform(date, "dd.MM.yy, HH:mm");
    } else {
      return this.datePipe.transform(date, "dd.MM.yy");
    }
  }

  public openEdit(type, case_number, event) {
    event.claim = event.claim ? event.claim._id : null;
    this.modalService.openModal({
      type: MODAL.ADD_EVENT,
      data: {
        type: type,
        case_number: case_number,
        action: "edit",
        obj: event,
      },
    });
  }
  public deleteEvent(id) {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: id,
        type: "event",
      },
    });
  }
}
