import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_FORMATS, NativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import {
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
} from "@mat-datetimepicker/core";
import { CalendarModule, DateAdapter } from "angular-calendar";
// import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { IMaskModule } from "angular-imask";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { ExcelExportDirective } from "../directives/excel-export.directive";
import { BackButtonComponent } from "./back-button/back-button.component";
import { CaseEventsComponent } from "./case-events/case-events.component";
import { CaseFilesComponent } from "./case-files/case-files.component";
import { CaseInputComponent } from "./case-input/case-input.component";
import { CaseProceduralComponent } from "./case-procedural/case-procedural.component";
import { CaseSelectComponent } from "./case-select/case-select.component";
import { CaseSideAnalyzeComponent } from "./case-side-analyze/case-side-analyze.component";
import { CaseSideOutputComponent } from "./case-side-output/case-side-output.component";
import { CaseTableComponent } from "./case-table/case-table.component";
import { CaseTasksComponent } from "./case-tasks/case-tasks.component";
import { ClaimSelectComponent } from "./claim-select/claim-select.component";
import { CommentsComponent } from "./comments/comments.component";
import { CourtInputComponent } from "./court-input/court-input.component";
import { DateTimeInputComponent } from "./date-time-input/date-time-input.component";
import { CaseHistoryDirective } from "./directives/case-history.directive";
import { JudgeModalDirective } from "./directives/judge-modal.directive";
import { DrawerComponent } from "./drawer/drawer.component";
import { FilterContactsComponent } from "./filter-contacts/filter-contacts.component";
import { FilterComponent } from "./filter/filter.component";
import { JudgeInputComponent } from "./judge-input/judge-input.component";
import { LexiaInputComponent } from "./lexia-input/lexia-input.component";
import { ModalComponent } from "./modal/modal.component";
import { AddEventComponent } from "./modals/add-event/add-event.component";
import { AddProceduralComponent } from "./modals/add-procedural/add-procedural.component";
import { ArchiveModalComponent } from "./modals/archive-modal/archive-modal.component";
import { CalendarModalComponent } from "./modals/calendar-modal/calendar-modal.component";
import { ClientModalComponent } from "./modals/client-modal/client-modal.component";
import { EditCourtComponent } from "./modals/edit-court/edit-court.component";
import { HelperModalComponent } from "./modals/helper-modal/helper-modal.component";
import { HistoryModalComponent } from "./modals/history-modal/history-modal.component";
import { RemoveModalComponent } from "./modals/remove-modal/remove-modal.component";
import { TransferModalComponent } from "./modals/transfer-modal/transfer-modal.component";
import { NotificationComponent } from "./notification/notification.component";
import { getPaginatorIntl } from "./paginator.intl";
import { RegisterDataComponent } from "./register-data/register-data.component";
import { SelectComponent } from "./select/select.component";
import { UserInputComponent } from "./user-input/user-input.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const MATERIAL = [
  MatTableModule,
  MatDatepickerModule,
  NativeDateModule,
  MatInputModule,
  MatDatetimepickerModule,
  MatNativeDatetimeModule,
  MatPaginatorModule,
  MatTooltipModule,
];
const MY_NATIVE_DATE_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  declarations: [
    CaseTableComponent,
    FilterComponent,
    ModalComponent,
    AddProceduralComponent,
    AddEventComponent,
    UserInputComponent,
    CourtInputComponent,
    DateTimeInputComponent,
    SelectComponent,
    JudgeInputComponent,
    CaseProceduralComponent,
    CaseEventsComponent,
    CaseTasksComponent,
    HistoryModalComponent,
    CaseHistoryDirective,
    CaseInputComponent,
    CalendarModalComponent,
    FilterContactsComponent,
    BackButtonComponent,
    RemoveModalComponent,
    EditCourtComponent,
    HelperModalComponent,
    JudgeModalDirective,
    ArchiveModalComponent,
    LexiaInputComponent,
    ClientModalComponent,
    ExcelExportDirective,
    CaseSelectComponent,
    CommentsComponent,
    CaseFilesComponent,
    TransferModalComponent,
    NotificationComponent,
    ClaimSelectComponent,
    DrawerComponent,
    CaseSideAnalyzeComponent,
    CaseSideOutputComponent,
    RegisterDataComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    PerfectScrollbarModule,
    IMaskModule,
    ...MATERIAL,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    FormsModule,
    ...MATERIAL,
    CaseTableComponent,
    PerfectScrollbarModule,
    FilterComponent,
    ModalComponent,
    UserInputComponent,
    CourtInputComponent,
    DateTimeInputComponent,
    ReactiveFormsModule,
    IMaskModule,
    SelectComponent,
    JudgeInputComponent,
    CaseProceduralComponent,
    CaseEventsComponent,
    CaseTasksComponent,
    CaseHistoryDirective,
    CalendarModule,
    FilterContactsComponent,
    BackButtonComponent,
    JudgeModalDirective,
    LexiaInputComponent,
    ExcelExportDirective,
    CaseSelectComponent,
    CommentsComponent,
    CaseFilesComponent,
    NotificationComponent,
    ClaimSelectComponent,
    DrawerComponent,
    CaseSideAnalyzeComponent,
    CaseSideOutputComponent,
    RegisterDataComponent,
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_NATIVE_DATE_FORMATS },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
})
export class SharedModule {}
