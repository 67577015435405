import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SettingModel, FilterDataModel } from '../models/setting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public reload$: Subject<string> = new Subject();
  public settings: SettingModel = new SettingModel();
  public filter: FilterDataModel = new FilterDataModel();
  public emptyFilter: Subject<FilterDataModel> = new Subject();
  public settings$: BehaviorSubject<SettingModel> = new BehaviorSubject(this.settings);
  public filter$: BehaviorSubject<FilterDataModel> = new BehaviorSubject(this.filter);
  constructor() { 
  }

  public getSettings() {
    return this.settings$.asObservable();
  }
  public getFilter() {
    return this.filter$.asObservable();
  }

  public getReload() {
    return this.reload$.asObservable();
  }
  public reloadData(type) {
    this.reload$.next(type)
  }
  public getEmptyFilter$() {
    return this.emptyFilter.asObservable();
  }


  public toggleFilter() {
    this.settings.showFilter = !this.settings.showFilter;
    this.settings$.next(this.settings);
  }

  public closeFilter() {
    this.settings.showFilter = false;
    this.settings$.next(this.settings);
  }
  public openFilter() {
    this.settings.showFilter = true;
    this.settings$.next(this.settings);
  }

  public dropFilter() {
    this.emptyFilter.next(new FilterDataModel())
    this.filter$.next(new FilterDataModel());
  }

  public setFilterValue(key, value) {
    this.filter[key] = value;
    this.filter$.next(this.filter)
  }

  public changeFilter(filter: FilterDataModel) {
    this.filter = filter;
    this.filter.show_drop = true;
    this.filter$.next(this.filter)
  }
}
