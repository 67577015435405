import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { ClaimService } from "src/app/services/claim.service";

@Component({
  selector: "app-claim-select",
  templateUrl: "./claim-select.component.html",
  styleUrls: ["./claim-select.component.less"],
})
export class ClaimSelectComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() number: string;
  @Output() onChange: EventEmitter<string> = new EventEmitter();
  public claims: any[] = [];
  constructor(public claimService: ClaimService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.number && changes.number.currentValue) {
      this.loadClaims(changes.number.currentValue);
    }
  }

  public loadClaims(number) {
    this.claimService.getClaimsByCase(number).subscribe((data: any[]) => {
      this.claims = [{ _id: null, title: "Справа" }, ...data];
    });
  }

  public selectSimple(claimId: string) {
    this.onChange.emit(claimId);
  }
}
