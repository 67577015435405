import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { EventModel } from "../models/event";
import { API } from "../data";
import { RouterService } from "./router.service";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(public http: HttpService, public router: RouterService) {}

  public getCaseEvents(number, claim = null): Observable<EventModel[]> {
    return this.http.get(`${API.EVENT}/case?number=${number}&claim=${claim}`);
  }

  public findOne(id) {
    return this.http.get(`${API.EVENT}/${id}`);
  }

  public find(): Observable<EventModel[]> {
    return this.http.get(API.EVENT);
  }
  public create(event: EventModel) {
    return this.http.post(API.EVENT, event);
  }
  public update(event: any) {
    return this.http.update(API.EVENT, event._id, event);
  }
  public delete(id) {
    return this.http.delete(API.EVENT, id);
  }
  public goToIndex() {
    this.router.navigate(["/events"]);
  }
}
