import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modal: { type: string, data?: any };
  public modal$: Subject<{ type?: string, data?: any }> = new Subject();
  constructor() { }


  public getModal() {
    return this.modal$.asObservable();
  }

  public openModal(modal: { type: string, data?: any }) {
    document.body.classList.add('overflowhidden')
    this.modal$.next(modal);
  }

  public closeModal() {
    document.body.classList.remove('overflowhidden')
    this.modal$.next(null);
  }
}
