import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { MODAL } from "src/app/data";
import { CaseService } from "src/app/services/case.service";
import { EventService } from "src/app/services/event.service";
import { ModalService } from "src/app/services/modal.service";
import { TaskService } from "src/app/services/task.service";
import { SettingService } from "src/app/services/setting.service";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-case-tasks",
  templateUrl: "./case-tasks.component.html",
  styleUrls: ["./case-tasks.component.less"],
})
export class CaseTasksComponent implements OnInit, OnDestroy {
  @Input() tasks: any[] = [];
  @Input() case_number: string;
  @Input() claimId: string;
  public settings$: Subscription;
  public today = new Date().getTime();
  public view = 1;
  constructor(
    public caseService: CaseService,
    public eventService: EventService,
    public modalService: ModalService,
    public taskService: TaskService,
    public settingService: SettingService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.case_number) {
      this.loadTasks(this.case_number);
    }
    this.settings$ = this.settingService.getReload().subscribe((type) => {
      if (type == "task") {
        this.loadTasks(this.case_number);
      }
    });
  }
  ngOnDestroy() {
    this.settings$.unsubscribe();
  }

  public completeTask(id) {
    this.taskService.completeTask(id);
  }

  public loadTasks(number) {
    this.taskService.getCaseTasks(number, this.claimId).subscribe((tasks) => {
      this.tasks = tasks;
    });
  }
  public openTask(id) {
    this.modalService.openModal({
      type: MODAL.CALENDAR,
      data: {
        id: id,
        type: "task",
      },
    });
  }
  public deleteTask(id) {
    this.modalService.openModal({
      type: MODAL.REMOVE,
      data: {
        _id: id,
        type: "task",
      },
    });
  }
  public addEvent(type: string = "event") {
    this.modalService.openModal({
      type: MODAL.ADD_EVENT,
      data: {
        type: type,
        case_number: this.case_number,
        action: "create",
        claim: this.claimId,
      },
    });
  }
  public getTaskDate(date) {
    let hours = new Date(date).getHours();
    if (hours > 0) {
      return this.datePipe.transform(date, "dd.MM.yy, HH:mm");
    } else {
      return this.datePipe.transform(date, "dd.MM.yy");
    }
  }
  public isTomorrow(date) {
    let now = new Date();
    let event_date = new Date(date);
    let tomorrow = `${now.getDate() + 1}.${now.getMonth()}`;
    return (
      tomorrow == `${event_date.getDate()}.${event_date.getMonth()}` ||
      `${now.getDate()}.${now.getMonth()}` ==
        `${event_date.getDate()}.${event_date.getMonth()}`
    );
  }

  public openEdit(type, obj) {
    this.modalService.openModal({
      type: MODAL.ADD_EVENT,
      data: {
        type: type,
        case_number: this.case_number,
        action: "edit",
        obj: obj,
      },
    });
  }
}
