import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/services/form.service';
import { FormGroup } from '@angular/forms';
import { JudgeService } from 'src/app/services/judge.service';
import { JudgeModel } from 'src/app/models/judge';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-helper-modal',
  templateUrl: './helper-modal.component.html',
  styleUrls: ['./helper-modal.component.less']
})
export class HelperModalComponent implements OnInit {
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public helperForm: FormGroup;
  public judges: JudgeModel[] = [];
  public judge: JudgeModel;
  constructor(
    public formService: FormService,
    public judgeService: JudgeService,
    public settingService: SettingService
  ) { 
    this.helperForm = this.formService.getJudgeGroup();
    this.helperForm.controls.status.setValue('assistant');
  }

  ngOnInit(): void {
    this.judgeService.find().subscribe(judges => {
      this.judges =judges;
    })
    if (this.data._id) {
      this.judgeService.findByID(this.data._id).subscribe(judge => {
        this.judge = judge;
      })
    }
  }

  public closeModal() {
    this.close.emit();
  }

  public createHelper() {
    this.judgeService.create(this.helperForm.value).subscribe(judge => {
      this.settingService.reloadData('contacts');
      this.closeModal();
    })
  }

}
