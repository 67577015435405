import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { UserModel } from '../models/user';
import { AuthService } from '../services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { CaseService } from '../services/case.service'; 
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  public profile: UserModel;
  public favs$: Subscription;
  public favs = [];
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public caseService: CaseService,
    public router: Router
  ) {
    this.userService.getProfile().subscribe(profile => {
      this.profile = profile;
    });
    this.router.events.subscribe(event => { 
      if (event instanceof NavigationEnd) {
        if (this.directiveRef) {
          this.directiveRef.scrollToTop();
        }
      }
    })
  }
  // public fdf() {}
  ngOnInit() {
    this.userService.loadProfile();
    this.favs$ = this.caseService.getFavorites$().subscribe(list => {
      this.favs = list;
    })
  }

  public logout() {
    this.authService.logout()
  }

  public openMobile() {
    document.getElementById('menuico').classList.toggle('active');
    document.getElementById('mobmenu').classList.toggle('active');
    document.body.classList.toggle('overflowhidden');
  }

  public closeMobile() {
    document.getElementById('menuico').classList.remove('active');
    document.getElementById('mobmenu').classList.remove('active');
    document.body.classList.remove('overflowhidden');
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
