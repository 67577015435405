import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-lexia-input',
  templateUrl: './lexia-input.component.html',
  styleUrls: ['./lexia-input.component.less']
})
export class LexiaInputComponent implements OnInit {
  @Output() select: EventEmitter<any> = new EventEmitter();
  public data: any;
  @Input() is_lexia: boolean = true;
  @Input() number: string;
  @Input() error: string = 'VALID';
  public lexia_404: boolean = false;
  public projects: any[] = [];
  constructor(
    public caseService: CaseService,
    public contactService: ContactService
  ) { }

  ngOnInit(): void {
    this.loadProjects();
  }

  public getLexiaProject() {
    this.error = 'VALID';
    this.lexia_404 = false,
      this.caseService.getLexiaProject(this.number).subscribe(res => {
        if (!res) {
          this.lexia_404 = true;
          return false;
        }
        this.data = res;
        this.select.emit(this.data);
      })
  }

  public selectProject(number) {
    this.lexia_404 = false,
    this.caseService.getLexiaProject(this.number).subscribe(res => {
      if (!res) {
        this.lexia_404 = true;
        return false;
      }
      this.data = res;
      this.select.emit(this.data);
    })
  }



  public loadProjects() {
    this.contactService.getProjects().subscribe(list => {
      this.projects = list;
    })
  }
}
