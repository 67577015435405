import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { FormGroup } from "@angular/forms";
import { CaseService } from "src/app/services/case.service";
import { SettingService } from "src/app/services/setting.service";

@Component({
  selector: "app-add-procedural",
  templateUrl: "./add-procedural.component.html",
  styleUrls: ["./add-procedural.component.less"],
})
export class AddProceduralComponent implements OnInit {
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  public items = [
    { key: 1, title: "Вирок" },
    { key: 2, title: "Постанова" },
    { key: 3, title: "Рішення" },
    { key: 4, title: "Судовий наказ" },
    { key: 5, title: "Ухвала" },
    { key: 6, title: "Окрема ухвала" },
    { key: 7, title: "Окрема думка" },
    { key: 8, title: "Апеляційну скаргу доповнено/змінено" },
    { key: 9, title: "Апеляційну скаргу залишено без руху" },
    {
      key: 10,
      title: "Апеляційну скаргу направлено до суду апеляційної інстанції",
    },
    {
      key: 11,
      title: "Апеляційну скаргу отримано судом апеляційної інстанції",
    },
    { key: 12, title: "Апеляційну скаргу повернуто" },
    { key: 13, title: "Виданий наказ або виконавчий лист" },
    { key: 14, title: "Витребувано матеріали справи" },
    { key: 15, title: "Відкрито апеляційне провадження: за участі сторін" },
    { key: 16, title: "Відкрито апеляційне провадження: письмове провадження" },
    { key: 17, title: "Відкрито касаційне провадження" },
    { key: 18, title: "Відкрито провадження: загальне провадження" },
    { key: 19, title: "Відкрито провадження: спрощене позовне провадження" },
    { key: 20, title: "Відмовлено у відкритті апеляційного провадження" },
    { key: 21, title: "Відмовлено у відкритті касаційного провадження" },
    { key: 22, title: "Відмовлено у відкритті провадження" },
    {
      key: 23,
      title: "Відмовлено у задоволенні заяви про забезпечення позову",
    },
    { key: 24, title: "Залучено співвідповідача" },
    { key: 25, title: "Залучено третю особу без самостійних вимог" },
    { key: 26, title: "Замінено неналежного відповідача" },
    { key: 27, title: "Замінено позивача" },
    { key: 28, title: "Зареєстровано заяву/клопотання про усунення недоліків" },
    { key: 29, title: "Застосовано зустрічне забезпечення позову" },
    {
      key: 30,
      title:
        "Зупинено виконання рішення суду першої інстанції та постанови суду апеляційної інстанції",
    },
    { key: 31, title: "Зупинено дію постанови суду апеляційної інстанції" },
    { key: 32, title: "Зупинено дію рішення суду першої інстанції" },
    {
      key: 33,
      title: "Зупинено дію судових рішень першої та апеляційної інстанції",
    },
    { key: 34, title: "Інше" },
    { key: 35, title: "Касаційну скаргу доповнено/змінено" },
    { key: 36, title: "Касаційну скаргу залишено без руху" },
    { key: 37, title: "Касаційну скаргу повернуто" },
    {
      key: 38,
      title: "Касаційну скаргу призначено до розгляду: письмове провадження",
    },
    {
      key: 39,
      title:
        "Касаційну скаргу призначено до розгляду: у попередньому судовому засіданні",
    },
    {
      key: 40,
      title: "Касаційну скаргу призначено до розгляду: у судовому засіданні",
    },
    { key: 41, title: "Направлена/вручена повістка" },
    { key: 42, title: "Направлене/вручене рішення/ухвала/постанова" },
    { key: 43, title: "Отримано матеріали справи" },
    { key: 44, title: "Підготовче засідання" },
    { key: 45, title: "Підписаний повний текст рішення" },
    { key: 46, title: "Подана апеляційна скарга" },
    { key: 47, title: "Подана відповідь на відзив" },
    { key: 48, title: "Подана заява" },
    { key: 49, title: "Подана заява про забезпечення позову" },
    {
      key: 50,
      title: "Подана заява про перегляд за нововиявленими обставинами",
    },
    { key: 51, title: "Подана позовна заява" },
    { key: 52, title: "Подане клопотання про відкладення розгляду справи" },
    { key: 53, title: "Подане клопотання про призначення експертизи" },
    { key: 54, title: "Поданий відзив" },
    { key: 55, title: "Поданий відзив на апеляційну скаргу" },
    { key: 56, title: "Подані заперечення" },
    { key: 57, title: "Подані пояснення" },
    { key: 58, title: "Подано висновок експерта/спеціаліста" },
    { key: 59, title: "Подано відзив на касаційну скаргу" },
    { key: 60, title: "Подано заяву про приєднання до апеляційної скарги" },
    { key: 61, title: "Подано заяву про приєднання до касаційної скарги" },
    { key: 62, title: "Подано позов третьої особи з самостійними вимогами" },
    { key: 63, title: "Позов забезпечено" },
    { key: 64, title: "Позовну заяву залишено без руху" },
    { key: 65, title: "Позовну заяву повернуто без розгляду" },
    { key: 66, title: "Подана касаційна скарга" },
    { key: 67, title: "Призначено підготовче засідання" },
    { key: 68, title: "Призначено судове засідання" },
    {
      key: 69,
      title: "Призначено судове засідання в суді апеляційної інстанції",
    },
    { key: 70, title: "Справу передано на розгляд Великої Палати" },
    { key: 71, title: "Справу передано на розгляд об'єднаної палати" },
    { key: 72, title: "Справу передано на розгляд палати" },
    { key: 73, title: "Справу прийнято до розгляду Великою Палатою" },
    { key: 74, title: "Судове засідання" },
  ];
  public history: FormGroup;
  constructor(
    public settingService: SettingService,
    public formService: FormService,
    public caseService: CaseService
  ) {
    this.history = this.formService.getProceduralGroup();
  }

  ngOnInit(): void {
    this.history.controls.case_number.setValue(this.data.case_number);
    this.history.controls.claim.setValue(
      this.data.claim ? this.data.claim : null
    );

    if (this.data.action == "edit") {
      this.history = this.formService.getProceduralGroupWithData(this.data.obj);
    }
  }

  public closeModal() {
    this.close.emit();
  }

  public selectItem(item) {
    this.history.controls.justice_name.setValue(item.title);
    this.history.controls.justice_code.setValue(item.key);
  }

  public addProcedural() {
    this.caseService.addProcedural(this.history.value).subscribe((res) => {
      this.caseService.setCaseId(this.data.case_id);
      this.settingService.reloadData("procedural");
      this.closeModal();
    });
  }
  public updateProcedural() {
    this.caseService.updateProcedural(this.history.value).subscribe((item) => {
      this.settingService.reloadData("procedural");
      this.closeModal();
    });
  }

  public onClaimChange(claimId) {
    this.history.controls.claim.setValue(claimId);
  }
}
