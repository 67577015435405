import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { FilterDataModel } from 'src/app/models/setting';
import { SettingService } from 'src/app/services/setting.service';
import { Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { CourtService } from 'src/app/services/court.service';
import { CourtModel } from 'src/app/models/court';
import { CASE_TYPES } from '../../data'
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less']
})
export class FilterComponent implements OnInit {
  public case_types = CASE_TYPES;
  public CASE_TYPE_PARENT = [];
  public CASE_TYPE_CHILD: any = {};
  @Input() public hidden_filters: string[] = [];
  public court_name$: Subject<string> = new Subject();
  public court_name: string;
  public court_result: CourtModel[] = [];
  public filterData: FilterDataModel = new FilterDataModel();
  public filter: any = {};
  public count: any = [];
  public judgment_code: any[] = [{value: 1, title: 'Цивільнa'}, {value: 2, title: 'Кримінальнa'}, {value: 3, title: 'Господарськa'}, {value: 4, title: 'Адміністративнa'}, {value: 5, title: 'Адмінправопорушення'}, {value: 6, title: 'Міжнародний арбітраж'}, {value: 7, title: 'Міжнародні суди'}, {value: 8, title: 'Іноземні суди'}]
  constructor(
    public caseService: CaseService,
    public courtService: CourtService,
    public settingSservice: SettingService,
    public cdr: ChangeDetectorRef
  ) {
    this.case_types.forEach(type => {
      this.CASE_TYPE_CHILD[type.parent.id] = type.children;
      this.CASE_TYPE_PARENT.push({ id: type.parent.id, text: type.parent.text })
    })
  }

  ngOnInit() {
    this.caseService.getCasesFilter().subscribe(filter => {
      this.filter = filter;
    });
    this.court_name$.pipe(debounceTime(200)).subscribe(query => {
      this.courtService.findByName(query).subscribe(courts => {
        this.court_result = courts;
      })
    });
    this.settingSservice.getEmptyFilter$().subscribe(filter => {
      this.filterData = filter;
    })
  }

  public isChecked(key, value) {
    return this.filterData[key].indexOf(value) != -1
  }

  public changeFilterArray(key, value) {
    const index = this.filterData[key].indexOf(value);
    if (index == -1) {
      this.filterData[key].push(value)
    } else {
      this.filterData[key].splice(index, 1)
    }
    this.settingSservice.changeFilter(this.filterData);
  }

  public updateFilter() {
    this.settingSservice.changeFilter(this.filterData); 
  }

  public changeFilterType(key, value) {
    this.filterData[key] = value;
    this.settingSservice.changeFilter(this.filterData)
  }

  public changeCourtName(event) {
    this.court_name$.next(event)
  }

  public addCourt(court, i) {
    if (!this.filter.courts.filter(c => c._id == court._id).length) {
      this.filter.courts.push({ _id: court._id, name: court.name });
    }
    this.court_result.splice(i, 1);
    this.changeFilterArray('courts', court._id)
  }

  public changeDate(date, field) {
    this.changeFilterType(field, date.value)
  }

  public isHidden(filter) {
    if (!this.hidden_filters.length) { return true }
    return this.hidden_filters.indexOf(filter) != -1;
  }

  public showEventTypeFilter() {
    return window.location.href.indexOf('calendar') != -1;
  }

  public addCaseType() {
    this.filterData.type.push({
      id: '',
      children: []
    })
  }

}
