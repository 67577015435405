import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(public http: HttpClient) {}

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }
  public delete<T>(url: string, id: string | number): Observable<T> {
    return this.http.delete<T>(`${url}/${id}`);
  }

  public update<T>(url: string, id: string, body: any): Observable<any> {
    return this.http.put<T>(`${url}/${id}`, body);
  }
}
