import { Directive, OnInit, Input, HostListener } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { MODAL } from 'src/app/data';

@Directive({
  selector: '[appCaseHistory]'
})
export class CaseHistoryDirective implements OnInit {
  @Input('appCaseHistory') case_id: string;
  @Input('type') type: string = 'history';
  @HostListener('click') onClick() { 
    this.modalService.openModal({
      type: MODAL.CASE_HISTORY,
      data: {
        case_id: this.case_id,
        type: this.type
      }
    })
  }
  constructor(
    public modalService: ModalService
  ) { }

  ngOnInit() {
  }

}
