import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { API } from "../data";
import { TaskModel } from "../models/task";
import { Observable } from "rxjs";
import { RouterService } from "./router.service";
import { SettingService } from "./setting.service";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor(
    public http: HttpService,
    public router: RouterService,
    public settingService: SettingService
  ) {}

  public find(): Observable<TaskModel[]> {
    return this.http.get(API.TASK);
  }
  public getCaseTasks(number, claim = null): Observable<TaskModel[]> {
    return this.http.get(`${API.TASK}/case?number=${number}&claim=${claim}`);
  }
  public findByID(id): Observable<TaskModel> {
    return this.http.get(`${API.TASK}/${id}`);
  }
  public create(task: TaskModel) {
    return this.http.post(API.TASK, task);
  }
  public update(id: string, task: TaskModel) {
    return this.http.update(API.TASK, id, task);
  }
  public delete(id) {
    return this.http.delete(API.TASK, id);
  }
  public completeTask(id) {
    this.http.get(`${API.TASK}/complete/${id}`).subscribe((task) => {
      this.settingService.reloadData("task");
    });
  }
  public goToIndex() {
    this.router.navigate(["/tasks"]);
  }
}
