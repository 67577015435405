import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.less"],
})
export class NotificationComponent implements OnInit {
  public notification$: Observable<any>;
  public text = {
    subscribe: `Ви підписались на моніторинг`,
    unsubscribe: `Ви відписались від моніторингу`,
    exists: `Моніторинг для цього ЄДРПОУ вже створено, ти можеш підписатися для отримання повідомлень`,
    unarchive: `Моніторинг знов активний. Всі користувачі будуть отримувати 
    e-mail`,
    archive: `Моніторинг архівовано. Всі користувачі не будуть отримувати  e-mail`,
  };
  constructor(public notificationService: NotificationService) {
    this.notification$ = this.notificationService.getNotification();
  }

  ngOnInit(): void {}
}
