import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private notification$: ReplaySubject<{ status: string; type: string }> =
    new ReplaySubject();

  constructor() {}

  public getNotification() {
    return this.notification$.asObservable();
  }

  public showNotification(data: { status: string; type: string }) {
    this.notification$.next(data);
    setTimeout(() => {
      this.notification$.next(null);
    }, 2500);
  }
}
